import { type ModuleListOutputItem } from 'src/__generated__/InternalApiTypes';
import { type TransformedOrganizationProductListOutputItem } from 'src/services/farmApi';

import { type MatrixColumn, type MatrixLayout } from '../types';
import { moduleToMatrixColumn } from './moduleToMatrixColumn';
import { productToMatrixRow } from './productToMatrixRow';

/**
 * Builds an object containing assessment config matrix columns and rows.
 * @param productList - List of products to include in the matrix
 * @param moduleList - List of modules to include in the matrix
 * @returns
 */

export const buildRowsColumns = (
  productList: TransformedOrganizationProductListOutputItem[] = [],
  moduleList: ModuleListOutputItem[] = [],
): MatrixLayout => {
  // Columns uses a reduce function to build the hierarchy structure of standards.
  const columns = moduleList.reduce(
    (acc: MatrixColumn[], module: ModuleListOutputItem): MatrixColumn[] => {
      // Create a new column for the module.
      const column = moduleToMatrixColumn(module);

      if (acc.findIndex((c) => c.id === column.id) < 0) {
        acc.push(column);
      }
      return acc;
    },
    [],
  );

  // Rows is more simple: just map over the list.
  const rows = productList.map((c) => productToMatrixRow(c));

  return {
    initialized: columns.length > 0 && rows.length > 0,
    columns,
    rows,
  };
};
