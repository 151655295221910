import {
  type PaginatedStandardSetterEvidenceListOutput,
  type StandardSetterEvidenceListParams,
} from 'src/__generated__/InternalApiTypes';

import { baseApi } from '../baseApi';
import { injectQueryParams } from '../helpers';
import { TagType } from '../TagType';

const RESOURCE = 'standard-setter-evidence';

const standardSetterEvidenceEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getStandardSetterEvidenceList: build.query<
      PaginatedStandardSetterEvidenceListOutput,
      StandardSetterEvidenceListParams
    >({
      query: ({ tid, ...restParams }) =>
        injectQueryParams(`/tid/${tid}/${RESOURCE}/`, restParams, true),
      providesTags: [TagType.StandardSetterEvidence],
    }),
  }),
  overrideExisting: false,
});

export const { useGetStandardSetterEvidenceListQuery } = standardSetterEvidenceEndpoints;
