import { type ProductGroupListOutputItem } from 'src/__generated__/InternalApiTypes';

import { baseApi } from '../baseApi';
import { TagType } from '../TagType';

const RESOURCE = 'product-groups';

const productGroupEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getProductGroupList: build.query<ProductGroupListOutputItem[], void>({
      query: () => `/${RESOURCE}/`,
      providesTags: [TagType.ProductGroups, TagType.UserProfile],
    }),
  }),
  overrideExisting: false,
});

export const { useGetProductGroupListQuery } = productGroupEndpoints;
