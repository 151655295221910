/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export enum AccountTypeEnum {
  Farmer = 'farmer',
  Auditor = 'auditor',
  FarmEmployee = 'farm_employee',
}

export interface AgriplaceCriticalityDetailOutput {
  /** @format uuid */
  uuid?: string;
  /** @maxLength 512 */
  name: string;
  /** @pattern ^[-a-zA-Z0-9_]+$ */
  slug: string;
  /**
   * @min 0
   * @max 2147483647
   */
  priority?: number;
}

export interface AgriplaceCriticalityListOutputItem {
  /** @format uuid */
  uuid?: string;
  /** @maxLength 512 */
  name: string;
  /** @pattern ^[-a-zA-Z0-9_]+$ */
  slug: string;
  /**
   * @min 0
   * @max 2147483647
   */
  priority?: number;
}

export interface AgriplaceMeasurementDetailOutput {
  /** @format uuid */
  uuid?: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /** @maxLength 512 */
  name?: string | null;
  /** @pattern ^[-a-zA-Z0-9_]+$ */
  code: string;
}

export interface AgriplaceMeasurementListOutputItem {
  /** @format uuid */
  uuid?: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /** @maxLength 512 */
  name?: string | null;
  /** @pattern ^[-a-zA-Z0-9_]+$ */
  code: string;
}

export enum AnswerTypeEnum {
  String = 'string',
  Number = 'number',
  Date = 'date',
  Boolean = 'boolean',
  Static = 'static',
}

export interface AssessmentAgriplaceCriticalityListOutputItem {
  /** @format uuid */
  uuid?: string;
  /** @maxLength 512 */
  name: string;
  /** @pattern ^[-a-zA-Z0-9_]+$ */
  slug: string;
  /**
   * @min 0
   * @max 2147483647
   */
  priority?: number;
}

export interface AssessmentBaseModuleOutputItem {
  /** @format uuid */
  uuid?: string;
  /** @maxLength 512 */
  name: string;
  logo: SimpleFile;
  small_logo: SimpleFile | null;
  /** @format uuid */
  standard_id: string;
}

export interface AssessmentCategoryEvidenceTypeControlPointListOutputItem {
  /** @format uuid */
  uuid?: string;
  /** @maxLength 2048 */
  main_text: string;
  /** @maxLength 128 */
  code: string;
  /** @maxLength 128 */
  display_code?: string | null;
  logo: SimpleFile | null;
}

export interface AssessmentCategoryEvidenceTypeListOutputItem {
  /** @format uuid */
  uuid: string;
  name: string;
  alternative_name: string;
  taxonomic_level: number;
  evidence_type_ids: string[];
  parent_category_name: string;
  /** @format uuid */
  parent_category_id: string;
}

export interface AssessmentCategoryEvidenceTypeStatusOutput {
  category?: AssessmentCategoryStatusOutput;
  evidence_types?: AssessmentEvidenceTypeStatusOutput[];
}

export interface AssessmentConfigBulkUpdateInput {
  /** @format uuid */
  assessment_id: string;
  assessment_config?: AssessmentConfigBulkUpdateInput[];
}

export interface AssessmentConfigBulkUpdateOutput {
  /** @format uuid */
  uuid: string;
  /** @format uuid */
  standard_id: string;
  /** @format uuid */
  assessment_id: string;
  /** @format uuid */
  organization_cultivation_id: string;
  malus_points: number;
  selection_status: string;
}

export interface AssessmentConfigListOutput {
  /** @format uuid */
  uuid: string;
  /** @format uuid */
  standard_id: string;
  /** @format uuid */
  assessment_id: string;
  /** @format uuid */
  organization_cultivation_id: string;
  malus_points: number;
  selection_status: string;
}

export interface AssessmentConfigUpdateInput {
  /** @format uuid */
  standard_id: string;
  /** @format uuid */
  organization_cultivation_id?: string | null;
  malus_points?: number | null;
  /** @default "included" */
  selection_status?: SelectionStatusEnum;
}

export interface AssessmentConfigUpdateOutput {
  /** @format uuid */
  uuid: string;
  /** @format uuid */
  standard_id: string;
  /** @format uuid */
  assessment_id: string;
  /** @format uuid */
  organization_cultivation_id: string;
  malus_points: number;
  selection_status: string;
}

export interface AssessmentConfirmedInput {
  confirmation_status: ConfirmationStatusEnum;
}

export interface AssessmentConfirmedOutput {
  /** @format uuid */
  uuid?: string;
  /** @maxLength 512 */
  name: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /** @format date-time */
  started_date?: string | null;
  /** @format date-time */
  to_be_audited_date?: string | null;
  /** @format date-time */
  to_be_reviewed_date?: string | null;
  /** @format date-time */
  to_be_confirmed_date?: string | null;
  /** @format date-time */
  confirmed_date?: string | null;
  /** @format date-time */
  declined_date?: string | null;
  /** @format date-time */
  submitted_date?: string | null;
  /** @format date-time */
  completed_date?: string | null;
  is_to_be_audited: boolean;
  is_submitted: boolean;
  status?: AssessmentStatusEnum;
  /** @format uuid */
  author_id: string | null;
  /** @format uuid */
  workflow_id: string;
}

export interface AssessmentControlPointAnswerBulkUpdateInput {
  /** @format uuid */
  assessment_id: string;
  /** @format uuid */
  control_point_id: string;
  dynamic_answer?: string | number;
  answer_ids?: string[];
  organization_cultivation_ids: string[];
  justification_text?: string;
  additional_detail_text?: string;
}

export interface AssessmentControlPointAnswerBulkUpdateOutput {
  /** @format uuid */
  uuid?: string;
  /** @format uuid */
  assessment_id: string;
  /** @format uuid */
  answer_set_id: string | null;
  /** @format uuid */
  control_point_id: string;
  /** @maxLength 2048 */
  justification_text?: string | null;
  /** @maxLength 2048 */
  additional_detail_text?: string | null;
  /** @format uuid */
  answer_set_by_trigger_id: string | null;
  dynamic_answer?: Record<string, any>;
  /** @format uuid */
  organization_cultivation_id: string | null;
  /** @format double */
  points?: number | null;
  has_associated_visibility_triggers: boolean;
  is_answer_set_by_evidence?: boolean;
}

export interface AssessmentControlPointAnswerCreateInput {
  /** @format uuid */
  assessment_id: string;
  answer_ids?: string[];
  justification_text?: string;
  additional_detail_text?: string;
  /** @format uuid */
  control_point_id: string;
  dynamic_answer?: string | number;
  /** @format uuid */
  organization_cultivation_id?: string;
}

export interface AssessmentControlPointAnswerCreateOutput {
  /** @format uuid */
  uuid?: string;
  /** @format uuid */
  assessment_id: string;
  /** @format uuid */
  answer_set_id: string | null;
  /** @format uuid */
  control_point_id: string;
  /** @maxLength 2048 */
  justification_text?: string | null;
  /** @maxLength 2048 */
  additional_detail_text?: string | null;
  /** @format uuid */
  answer_set_by_trigger_id: string | null;
  dynamic_answer?: Record<string, any>;
  /** @format uuid */
  organization_cultivation_id: string | null;
  /** @format double */
  points?: number | null;
  has_associated_visibility_triggers: boolean;
  is_answer_set_by_evidence?: boolean;
}

export interface AssessmentControlPointAnswerDetailOutput {
  /** @format uuid */
  uuid?: string;
  /** @format uuid */
  assessment_id: string;
  answer_ids: string[];
  /** @format uuid */
  answer_set_id: string | null;
  /** @format uuid */
  control_point_id: string;
  /** @maxLength 2048 */
  justification_text?: string | null;
  /** @maxLength 2048 */
  additional_detail_text?: string | null;
  /** @format uuid */
  answer_set_by_trigger_id: string | null;
  trigger_data: {
    trigger_type: string;
    trigger_name: string | null;
    trigger_answer: string | null;
  };
  dynamic_answer?: Record<string, any>;
  /** @format uuid */
  organization_cultivation_id: string | null;
  /** @format double */
  points?: number | null;
  compliance_status?: ComplianceStatusEnum | null;
  compliance_status_label: string;
  is_answer_set_by_evidence?: boolean;
}

export interface AssessmentControlPointAnswerListOutputItem {
  /** @format uuid */
  uuid: string;
  /** @format uuid */
  control_point_id: string;
  /** @format uuid */
  assessment_id: string;
  answer_ids: string[];
  /** @format uuid */
  answer_set_id: string | null;
  justification_text: string | null;
  additional_detail_text: string | null;
  /** @format uuid */
  answer_set_by_trigger_id: string | null;
  trigger_data: {
    trigger_type: string;
    trigger_name: string | null;
    trigger_answer: string | null;
  };
  dynamic_answer: Record<string, any>;
  /** @format uuid */
  organization_cultivation_id: string | null;
  /** @format double */
  points: number | null;
  compliance_status: ComplianceStatusEnum | null;
  compliance_status_label: string;
  is_answer_set_by_evidence: boolean;
}

export interface AssessmentControlPointAnswerUpdateInput {
  answer_ids?: string[];
  justification_text?: string | null;
  additional_detail_text?: string | null;
  dynamic_answer?: string | number;
}

export interface AssessmentControlPointAnswerUpdateOutput {
  /** @format uuid */
  uuid?: string;
  /** @format uuid */
  assessment_id: string;
  /** @format uuid */
  answer_set_id: string | null;
  /** @format uuid */
  control_point_id: string;
  /** @maxLength 2048 */
  justification_text?: string | null;
  /** @maxLength 2048 */
  additional_detail_text?: string | null;
  /** @format uuid */
  answer_set_by_trigger_id: string | null;
  trigger_data: {
    trigger_type: string;
    trigger_name: string | null;
    trigger_answer: string | null;
  };
  dynamic_answer?: Record<string, any>;
  /** @format uuid */
  organization_cultivation_id: string | null;
  /** @format double */
  points?: number | null;
  has_associated_visibility_triggers: boolean;
  is_answer_set_by_evidence?: boolean;
}

export interface AssessmentControlPointBreadcrumbsListOutputItem {
  /** @format uuid */
  control_point_id: string;
  /** @format uuid */
  small_logo_id: string;
  breadcrumb: string;
}

export interface AssessmentControlPointCategoryEvidenceTypeListOutputItem {
  /** @format uuid */
  uuid: string;
  /** @format uuid */
  parent_category_id: string;
  name: string;
  taxonomic_level: number;
  evidence_type_ids: any[];
}

export interface AssessmentControlPointCommentCreateInput {
  /** @format uuid */
  assessment_id: string;
  /** @format uuid */
  control_point_id: string;
  text: string;
  /** @format uuid */
  parent_comment_id?: string;
}

export interface AssessmentControlPointCommentCreateOutput {
  /** @format uuid */
  uuid?: string;
  /** @format uuid */
  control_point_id: string;
  /** @format uuid */
  assessment_id: string;
  /** @format uuid */
  author_id: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /** @maxLength 2048 */
  text: string;
  /** @format uuid */
  parent_comment_id: string | null;
}

export interface AssessmentControlPointCommentDetailOutput {
  /** @format uuid */
  uuid?: string;
  /** @format uuid */
  control_point_id: string;
  /** @format uuid */
  assessment_id: string;
  /** @maxLength 2048 */
  text: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /** @format uuid */
  author_id: string;
  /** @format uuid */
  parent_comment_id: string | null;
}

export interface AssessmentControlPointCommentListOutputItem {
  /** @format uuid */
  uuid?: string;
  /** @format uuid */
  control_point_id: string;
  /** @format uuid */
  assessment_id: string;
  /** @maxLength 2048 */
  text: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /** @format uuid */
  author_id: string;
  author_name: string;
  /** @format uuid */
  parent_comment_id: string | null;
}

export interface AssessmentControlPointCommentOutputItem {
  /** @format uuid */
  uuid: string;
  /** @format uuid */
  assessment_id: string;
  /** @format uuid */
  control_point_id: string;
  text: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /** @format uuid */
  author_id: string;
  /** @format uuid */
  parent_comment_id: string;
  author_name: string;
  author_role: string;
}

export interface AssessmentControlPointCommentUpdateInput {
  text: string;
}

export interface AssessmentControlPointCommentUpdateOutput {
  /** @format uuid */
  uuid?: string;
  /** @format uuid */
  control_point_id: string;
  /** @format uuid */
  assessment_id: string;
  /** @format uuid */
  author_id: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /** @maxLength 2048 */
  text: string;
  /** @format uuid */
  parent_comment_id: string | null;
}

export interface AssessmentControlPointCultivationListOutputItem {
  /** @format uuid */
  control_point_id: string;
  organization_cultivations?: OrganizationCultivationsAssessmentControlPointCultivationList[];
}

export interface AssessmentControlPointDetailOutput {
  /** @format uuid */
  uuid: string;
  /** @format uuid */
  control_point_id: string;
  comments: AssessmentControlPointCommentOutputItem[];
  evidence: AssessmentControlPointEvidenceOutputItem[];
  shortcomings: AssessmentControlPointShortcomingOutputItem[];
  inputs: AssessmentControlPointInputOutputItem[];
  retailer_ids: string[];
  can_attach_evidence: boolean;
}

export interface AssessmentControlPointEvidenceBulkCreateInput {
  /** @format uuid */
  assessment_id: string;
  /** @format uuid */
  evidence_id: string;
  control_point_ids: string[];
}

export interface AssessmentControlPointEvidenceBulkCreateOutput {
  /** @format uuid */
  uuid?: string;
  /** @format uuid */
  control_point_id: string;
  /** @format uuid */
  assessment_id: string;
  /** @format uuid */
  evidence_id: string;
}

export interface AssessmentControlPointEvidenceCreateInput {
  /** @format uuid */
  assessment_id: string;
  /** @format uuid */
  control_point_id: string;
  /** @format uuid */
  evidence_id: string;
}

export interface AssessmentControlPointEvidenceCreateOutput {
  /** @format uuid */
  uuid?: string;
  /** @format uuid */
  control_point_id: string;
  /** @format uuid */
  assessment_id: string;
  /** @format uuid */
  evidence_id: string;
  is_auto_attached?: boolean;
}

export interface AssessmentControlPointEvidenceDetailOutput {
  /** @format uuid */
  uuid?: string;
  /** @format uuid */
  control_point_id: string;
  /** @format uuid */
  assessment_id: string;
  /** @format uuid */
  evidence_id: string;
  is_locked: boolean;
  is_auto_attached?: boolean;
}

export interface AssessmentControlPointEvidenceListOutputItem {
  /** @format uuid */
  uuid?: string;
  /** @format uuid */
  control_point_id: string;
  /** @format uuid */
  assessment_id: string;
  /** @format uuid */
  evidence_id: string;
  is_auto_attached?: boolean;
  evidence_file: SimpleFile | null;
}

export interface AssessmentControlPointEvidenceOutputItem {
  /** @format uuid */
  uuid: string;
  /** @format uuid */
  assessment_id: string;
  /** @format uuid */
  control_point_id: string;
  /** @format uuid */
  evidence_id: string;
}

export interface AssessmentControlPointEvidenceUpdateInput {
  /** @format uuid */
  evidence_id?: string;
}

export interface AssessmentControlPointEvidenceUpdateOutput {
  /** @format uuid */
  uuid?: string;
  /** @format uuid */
  control_point_id: string;
  /** @format uuid */
  assessment_id: string;
  /** @format uuid */
  evidence_id: string;
  is_auto_attached?: boolean;
}

export interface AssessmentControlPointInputListOutputItem {
  /** @format uuid */
  uuid?: string;
  /** @format uuid */
  assessment_id: string;
  /** @format uuid */
  control_point_id: string;
  control_point_input?: AssessmentControlPointInputControlPointInputList;
  /** @format uuid */
  answer_id: string | null;
  dynamic_answer?: Record<string, any>;
}

export interface AssessmentControlPointInputOutputItem {
  /** @format uuid */
  uuid: string;
  /** @format uuid */
  answer_id: string;
  dynamic_answer: string;
  /** @format uuid */
  control_point_id: string;
  control_point_input?: AssessmentControlPointControlPointInputList;
}

export interface AssessmentControlPointInputUpdateInputItem {
  /** @format uuid */
  answer_id: string;
  dynamic_answer?: string | number;
}

export interface AssessmentControlPointInputUpdateOutputItem {
  /** @format uuid */
  uuid?: string;
  /** @format uuid */
  assessment_id: string;
  /** @format uuid */
  control_point_id: string;
  control_point_input?: AssessmentControlPointInputControlPointInputUpdate;
  /** @format uuid */
  answer_id: string | null;
  dynamic_answer?: Record<string, any>;
}

export interface AssessmentControlPointListOutputItem {
  uuid: string;
  /** @format uuid */
  control_point_id: string;
  /** @format uuid */
  criticality_id: string | null;
  /** @format uuid */
  possible_answer_set_id: string | null;
  /** @maxLength 2048 */
  main_text: string;
  /** @maxLength 128 */
  code: string;
  /** @maxLength 128 */
  display_code?: string | null;
  level?: LevelEnum;
  allows_bulk_cultivation_answers?: boolean;
  is_evidence_shared_with_standard_setter?: boolean;
  is_multiple_choice?: boolean;
  show_control_point_level?: boolean;
  standard_setter?: string;
  /** @format uuid */
  module_id: string;
  is_flagged: boolean;
  control_point_answer_type: ControlPointAnswerTypeEnum;
  is_scored?: boolean;
  categories?: Categories[];
  /** @format uuid */
  agriplace_criticality_id: string;
  evidence_type_ids: string[];
  /** @format double */
  max_answer_value?: number | null;
  /** @format double */
  min_answer_value?: number | null;
  /**
   * @min 0
   * @max 2147483647
   */
  max_decimal_places?: number | null;
  /** @format uuid */
  level_3_module_id: string;
  can_attach_evidence?: boolean;
}

export interface AssessmentControlPointQuestionOutputItem {
  /** @format uuid */
  uuid: string;
  control_point_question?: AssessmentControlPointControlPointQuestionList;
}

/** For serializing Responses within an AssessmentControlPoint */
export interface AssessmentControlPointResponseOutputItem {
  /** @format uuid */
  uuid: string;
  /** @format uuid */
  assessment_id: string;
  /** @format uuid */
  control_point_id: string;
  /** @format uuid */
  cultivation_id: string;
  /** @format double */
  points: number;
  justification_text: string;
  additional_detail_text: string;
  compliance_status: ComplianceStatusEnum;
}

export interface AssessmentControlPointShortcomingCreateInput {
  /** @format uuid */
  assessment_id: string;
  /** @format uuid */
  control_point_id: string;
  text: string;
  assessment_control_point_evidence_ids?: string[];
  /** @format date-time */
  reported_date: string;
  occurrence?: OccurrenceEnum;
}

export interface AssessmentControlPointShortcomingCreateOutput {
  /** @format uuid */
  uuid?: string;
  /** @format uuid */
  control_point_id: string;
  /** @format uuid */
  assessment_id: string;
  /** @maxLength 2048 */
  text: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /** @format uuid */
  author_id: string | null;
  assessment_control_point_evidence_ids: string[];
  /** @format date-time */
  reported_date: string;
  corrective_action_ids: string[];
  occurrence?: OccurrenceEnum | null;
}

export interface AssessmentControlPointShortcomingDetailOutput {
  /** @format uuid */
  uuid?: string;
  /** @format uuid */
  control_point_id: string;
  /** @format uuid */
  assessment_id: string;
  /** @maxLength 2048 */
  text: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /** @format uuid */
  author_id: string | null;
  assessment_control_point_evidence_ids: string[];
  /** @format date-time */
  reported_date: string;
  corrective_action_ids: string[];
  occurrence?: OccurrenceEnum | null;
}

export interface AssessmentControlPointShortcomingListOutputItem {
  /** @format uuid */
  uuid?: string;
  /** @format uuid */
  control_point_id: string;
  /** @format uuid */
  assessment_id: string;
  /** @maxLength 2048 */
  text: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /** @format uuid */
  author_id: string | null;
  /** @format date-time */
  reported_date: string;
  occurrence?: OccurrenceEnum | null;
}

export interface AssessmentControlPointShortcomingOutputItem {
  /** @format uuid */
  uuid: string;
  /** @format uuid */
  control_point_id: string;
  /** @format uuid */
  assessment_id: string;
  text: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /** @format uuid */
  author_id: string;
  /** @format date-time */
  reported_date: string;
  occurrence: OccurrenceEnum;
}

export interface AssessmentControlPointShortcomingUpdateInput {
  text?: string;
  assessment_control_point_evidence_ids?: string[];
  /** @format date-time */
  reported_date?: string;
  occurrence?: OccurrenceEnum;
}

export interface AssessmentControlPointShortcomingUpdateOutput {
  /** @format uuid */
  uuid?: string;
  /** @format uuid */
  control_point_id: string;
  /** @format uuid */
  assessment_id: string;
  /** @maxLength 2048 */
  text: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /** @format uuid */
  author_id: string | null;
  assessment_control_point_evidence_ids: string[];
  /** @format date-time */
  reported_date: string;
  corrective_action_ids: string[];
  occurrence?: OccurrenceEnum | null;
}

export interface AssessmentControlPointUpdateInput {
  is_flagged: boolean;
}

export interface AssessmentControlPointUpdateOutput {
  /** @format uuid */
  uuid: string;
  /** @format uuid */
  control_point_id: string;
  /** @format uuid */
  criticality_id: string | null;
  /** @format uuid */
  possible_answer_set_id: string | null;
  /** @maxLength 2048 */
  main_text: string;
  /** @maxLength 128 */
  code: string;
  /** @maxLength 128 */
  display_code?: string | null;
  level?: LevelEnum;
  allows_bulk_cultivation_answers?: boolean;
  is_evidence_shared_with_standard_setter?: boolean;
  is_multiple_choice?: boolean;
  show_control_point_level?: boolean;
  standard_setter?: string;
  /** @format uuid */
  module_id: string;
  control_point_answer_type: ControlPointAnswerTypeEnum;
  is_flagged: boolean;
  is_scored?: boolean;
  /** @format uuid */
  agriplace_criticality_id: string | null;
}

export interface AssessmentCreateInput {
  name?: string;
  /** @format date-time */
  started_date?: string;
  /** @format uuid */
  standard_id?: string;
  client_identifier_value?: string;
  metadata?: AssessmentCreateCbAssessmentMetadataInput;
}

export interface AssessmentCreateOutput {
  /** @format uuid */
  uuid: string;
  name: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /** @format date-time */
  started_date: string;
  /** @format date-time */
  to_be_audited_date: string;
  /** @format date-time */
  to_be_reviewed_date: string;
  /** @format date-time */
  to_be_confirmed_date: string;
  /** @format date-time */
  confirmed_date: string;
  /** @format date-time */
  declined_date: string;
  /** @format date-time */
  submitted_date: string;
  /** @format date-time */
  completed_date: string;
  is_to_be_audited: boolean;
  is_submitted: boolean;
  status: string;
  /** @format uuid */
  author_id: string;
  /** @format uuid */
  workflow_id: string;
  client_identifiers: string[];
}

export interface AssessmentCriticalityProgressIndicationOutput {
  /** @format uuid */
  criticality_id: string;
  /** @format double */
  progress: number;
}

export interface AssessmentDetailOutput {
  /** @format uuid */
  uuid: string;
  name: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /** @format date-time */
  started_date: string;
  /** @format date-time */
  to_be_audited_date: string;
  /** @format date-time */
  to_be_reviewed_date: string;
  /** @format date-time */
  to_be_confirmed_date: string;
  /** @format date-time */
  confirmed_date: string;
  /** @format date-time */
  declined_date: string;
  /** @format date-time */
  submitted_date: string;
  /** @format date-time */
  completed_date: string;
  is_to_be_audited: boolean;
  is_submitted: boolean;
  /** @format uuid */
  author_id: string;
  /** @format uuid */
  workflow_id: string;
  audit_duration: number;
  client_identifiers: string[];
  standard_ids: string[];
  /** @format uuid */
  preliminary_question_set_id: string;
  status: string;
  module_cultivation_selections?: ModuleCultivationSelections[];
  inspection_type: string;
  status_label: string;
  inspection_type_label: string;
  retailer_ids: string[];
  metadata?: AssessmentDetailCbAssessmentMetadataOutput;
}

export interface AssessmentListOutputItem {
  /** @format uuid */
  uuid?: string;
  /** @maxLength 512 */
  name: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /** @format date-time */
  started_date?: string | null;
  /** @format date-time */
  to_be_audited_date?: string | null;
  /** @format date-time */
  to_be_reviewed_date?: string | null;
  /** @format date-time */
  to_be_confirmed_date?: string | null;
  /** @format date-time */
  confirmed_date?: string | null;
  /** @format date-time */
  declined_date?: string | null;
  /** @format date-time */
  submitted_date?: string | null;
  /** @format date-time */
  completed_date?: string | null;
  is_to_be_audited: boolean;
  is_submitted: boolean;
  status?: AssessmentStatusEnum;
  /** @format uuid */
  author_id: string | null;
  author_name: string;
  /** @format uuid */
  workflow_id: string;
  standard_logo_ids: string[];
  inspection_type?: InspectionTypeEnum | null;
  status_label: string;
  inspection_type_label: string;
  retailer_ids: string[];
  metadata?: AssessmentListCbAssessmentMetadataOutput;
}

export interface AssessmentModuleProgressOutput {
  /** @format uuid */
  module_id: string;
  module_name: string;
  module_level: number;
  total_questions: number;
  answered_questions: number;
  progress: number;
  hierarchy: string[];
}

export interface AssessmentModuleSidebarOutputItem {
  /** @format uuid */
  uuid?: string;
  /** @maxLength 512 */
  name: string;
  /** @format uuid */
  parent_id: string | null;
  level: number;
  index: number;
  tree_id: number;
}

export interface AssessmentOrganizationPlotCultivationCreateInput {
  /** @format uuid */
  product_id: string;
  /** @format uuid */
  production_technique_id: string;
  client_identifiers?: string[];
  /**
   * @min 2020
   * @max 2100
   */
  audit_year?: number;
}

export interface AssessmentOrganizationPlotCultivationCreateOutput {
  /** @format uuid */
  uuid?: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  organization_plot?: AssessmentPlotCultivationCreateOrganizationPlot;
  product?: AssessmentPlotCultivationCreateProduct;
  production_technique?: AssessmentPlotCultivationCreateProductionTechnique;
  /**
   * @min 2020
   * @max 2100
   */
  audit_year?: number | null;
  client_identifiers?: AssessmentPlotCultivationCreateClientIdentifier[];
}

export interface AssessmentOrganizationPlotCultivationDetailOutput {
  /** @format uuid */
  uuid?: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  organization_plot?: AssessmentPlotCultivationDetailOrganizationPlot;
  product?: AssessmentPlotCultivationDetailProduct;
  production_technique?: AssessmentPlotCultivationDetailProductionTechnique;
  client_identifiers?: AssessmentPlotCultivationDetailClientIdentifier[];
  can_be_edited: boolean;
  can_be_deleted: boolean;
  /**
   * @min 2020
   * @max 2100
   */
  audit_year?: number | null;
}

export interface AssessmentOrganizationPlotCultivationListOutputItem {
  /** @format uuid */
  uuid?: string;
  /** @maxLength 512 */
  name: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  product?: AssessmentPlotCultivationListProduct;
  production_technique?: AssessmentPlotCultivationListProductionTechnique;
  client_identifiers?: AssessmentPlotCultivationListClientIdentifier[];
  can_be_edited: boolean;
  can_be_deleted: boolean;
  /**
   * @min 2020
   * @max 2100
   */
  audit_year?: number | null;
}

export interface AssessmentOrganizationPlotCultivationUpdateInput {
  /** @format uuid */
  product_id?: string;
  /** @format uuid */
  production_technique_id?: string;
  client_identifiers?: string[];
  /**
   * @min 2020
   * @max 2100
   */
  audit_year?: number;
}

export interface AssessmentOrganizationPlotCultivationUpdateOutput {
  /** @format uuid */
  uuid?: string;
  /** @maxLength 512 */
  name: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  product?: AssessmentPlotCultivationUpdateProduct;
  production_technique?: AssessmentPlotCultivationUpdateProductionTechnique;
  /**
   * @min 2020
   * @max 2100
   */
  audit_year?: number | null;
  client_identifiers?: AssessmentPlotCultivationUpdateClientIdentifier[];
}

export interface AssessmentPreliminaryQuestionAnswerBulkUpdateDraftOutput {
  /** @format uuid */
  assessment_id: string;
  expected_changes?: PreliminaryQuestionAnswerListBulkUpdateDraftOutput;
  is_draft: boolean;
}

export interface AssessmentPreliminaryQuestionAnswerBulkUpdateInput {
  /** @format uuid */
  assessment_id: string;
  /** @default false */
  is_draft?: boolean;
  preliminary_question_answers: PreliminaryQuestionAnswerListBulkUpdateInput[];
}

export interface AssessmentPreliminaryQuestionAnswerBulkUpdateOutput {
  /** @format uuid */
  assessment_id: string;
  preliminary_question_answers?: PreliminaryQuestionAnswerListBulkUpdateOutput[];
  is_draft: boolean;
}

export interface AssessmentPreliminaryQuestionAnswerCreateInput {
  /** @format uuid */
  assessment_id: string;
  /** @format uuid */
  possible_answer_id: string;
  /** @format uuid */
  preliminary_question_id: string;
  dynamic_answer?: string | number;
}

export interface AssessmentPreliminaryQuestionAnswerCreateOutput {
  /** @format uuid */
  uuid?: string;
  /** @format uuid */
  assessment_id: string;
  /** @format uuid */
  possible_answer_set_id: string;
  /** @format uuid */
  preliminary_question_id: string;
  /** @format uuid */
  possible_answer_id: string;
  dynamic_answer: string | number;
}

export interface AssessmentPreliminaryQuestionAnswerDetailOutput {
  /** @format uuid */
  uuid?: string;
  /** @format uuid */
  assessment_id: string;
  /** @format uuid */
  possible_answer_id: string;
  preliminary_question_slug: string;
  /** @format uuid */
  possible_answer_set_id: string;
  /** @format uuid */
  preliminary_question_id: string;
  dynamic_answer: string | number;
  is_locked: boolean;
}

export interface AssessmentPreliminaryQuestionAnswerListOutputItem {
  /** @format uuid */
  uuid?: string;
  /** @format uuid */
  preliminary_question_id: string;
  preliminary_question_slug: string;
  /** @format uuid */
  assessment_id: string;
  /** @format uuid */
  possible_answer_id: string;
  /** @format uuid */
  possible_answer_set_id: string;
  dynamic_answer: string | number;
}

export interface AssessmentPreliminaryQuestionAnswerUpdateInput {
  /** @format uuid */
  possible_answer_id?: string;
  dynamic_answer?: string | number;
}

export interface AssessmentPreliminaryQuestionAnswerUpdateOutput {
  /** @format uuid */
  uuid?: string;
  /** @format uuid */
  assessment_id: string;
  /** @format uuid */
  possible_answer_set_id: string;
  /** @format uuid */
  preliminary_question_id: string;
  /** @format uuid */
  possible_answer_id: string;
  dynamic_answer: string | number;
}

export interface AssessmentProgressOutput {
  progress: number;
  auto_filled_progress: number;
}

export interface AssessmentStandardRequestApproveInput {
  /** @format uuid */
  assessment_id: string;
}

export interface AssessmentStandardRequestApproveOutput {
  /** @format uuid */
  uuid?: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /** @format uuid */
  assessment_id: string | null;
  /** @format uuid */
  standard_id: string;
  /** @format uuid */
  requester_organization_id: string;
  /** @format uuid */
  requester_user_profile_id: string | null;
  /** @maxLength 255 */
  client_identifier_value: string;
  status?: RequestStatusEnum;
  /** @format uuid */
  organization_id: string;
  requester_name: string | null;
  requester_email: string | null;
  approver_name: string | null;
  approver_email: string | null;
  /** @maxLength 512 */
  name: string;
}

export interface AssessmentStandardRequestCreateInput {
  name: string;
  /** @format uuid */
  standard_id: string;
  client_identifier_value: string;
  organization_name: string;
  organization_address: string;
  organization_postal_code: string;
  organization_city: string;
  organization_country: CountryEnum;
}

export interface AssessmentStandardRequestCreateOutput {
  /** @format uuid */
  uuid?: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /** @format uuid */
  assessment_id: string | null;
  /** @format uuid */
  standard_id: string;
  /** @format uuid */
  requester_organization_id: string;
  /** @format uuid */
  requester_user_profile_id: string | null;
  /** @maxLength 255 */
  client_identifier_value: string;
  status?: RequestStatusEnum;
  /** @format uuid */
  organization_id: string;
  requester_name: string | null;
  requester_email: string | null;
  approver_name: string | null;
  approver_email: string | null;
  /** @maxLength 512 */
  name: string;
}

export interface AssessmentStandardRequestDetailOutputItem {
  /** @format uuid */
  uuid?: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /** @format uuid */
  assessment_id: string | null;
  /** @format uuid */
  standard_id: string;
  /** @format uuid */
  requester_organization_id: string;
  /** @format uuid */
  requester_user_profile_id: string | null;
  /** @maxLength 255 */
  client_identifier_value: string;
  status?: RequestStatusEnum;
  /** @format uuid */
  organization_id: string;
  requester_name: string | null;
  requester_email: string | null;
  approver_name: string | null;
  approver_email: string | null;
  /** @maxLength 512 */
  name: string;
  /** @format date-time */
  status_modified_at: string;
  organization_name: string;
  status_label: string;
}

export interface AssessmentStandardRequestListOutputItem {
  /** @format uuid */
  uuid?: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /** @format uuid */
  assessment_id: string | null;
  /** @format uuid */
  standard_id: string;
  /** @format uuid */
  requester_organization_id: string;
  /** @format uuid */
  requester_user_profile_id: string | null;
  /** @maxLength 255 */
  client_identifier_value: string;
  status?: RequestStatusEnum;
  /** @format uuid */
  organization_id: string;
  requester_name: string | null;
  requester_email: string | null;
  /** @maxLength 512 */
  name: string;
  /** @format date-time */
  status_modified_at: string;
  organization_name: string;
  approver_email: string | null;
  status_label: string;
}

export interface AssessmentStandardRequestRejectOutput {
  /** @format uuid */
  uuid?: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /** @format uuid */
  assessment_id: string | null;
  /** @format uuid */
  standard_id: string;
  /** @format uuid */
  requester_organization_id: string;
  /** @format uuid */
  requester_user_profile_id: string | null;
  /** @maxLength 255 */
  client_identifier_value: string;
  status?: RequestStatusEnum;
  /** @format uuid */
  organization_id: string;
  requester_name: string | null;
  requester_email: string | null;
  approver_name: string | null;
  approver_email: string | null;
  /** @maxLength 512 */
  name: string;
}

export enum AssessmentStatusEnum {
  Draft = 'draft',
  Open = 'open',
  Submitted = 'submitted',
  ToBeAudited = 'to_be_audited',
  ToBeReviewed = 'to_be_reviewed',
  ToBeConfirmed = 'to_be_confirmed',
  Confirmed = 'confirmed',
  Declined = 'declined',
  Closed = 'closed',
}

export interface AssessmentSummaryShareableExportCreateInput {
  /** @format uuid */
  certification_body_id: string;
}

export interface AssessmentToBeAuditedOutput {
  /** @format uuid */
  uuid?: string;
  /** @maxLength 512 */
  name: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /** @format date-time */
  started_date?: string | null;
  /** @format date-time */
  to_be_audited_date?: string | null;
  /** @format date-time */
  to_be_reviewed_date?: string | null;
  /** @format date-time */
  to_be_confirmed_date?: string | null;
  /** @format date-time */
  confirmed_date?: string | null;
  /** @format date-time */
  declined_date?: string | null;
  /** @format date-time */
  submitted_date?: string | null;
  /** @format date-time */
  completed_date?: string | null;
  is_to_be_audited: boolean;
  is_submitted: boolean;
  status?: AssessmentStatusEnum;
  /** @format uuid */
  author_id: string | null;
  /** @format uuid */
  workflow_id: string;
}

export interface AssessmentToBeConfirmedOutput {
  /** @format uuid */
  uuid?: string;
  /** @maxLength 512 */
  name: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /** @format date-time */
  started_date?: string | null;
  /** @format date-time */
  to_be_audited_date?: string | null;
  /** @format date-time */
  to_be_reviewed_date?: string | null;
  /** @format date-time */
  to_be_confirmed_date?: string | null;
  /** @format date-time */
  confirmed_date?: string | null;
  /** @format date-time */
  declined_date?: string | null;
  /** @format date-time */
  submitted_date?: string | null;
  /** @format date-time */
  completed_date?: string | null;
  is_to_be_audited: boolean;
  is_submitted: boolean;
  status?: AssessmentStatusEnum;
  /** @format uuid */
  author_id: string | null;
  /** @format uuid */
  workflow_id: string;
}

export interface AssessmentToBeReviewedOutput {
  /** @format uuid */
  uuid?: string;
  /** @maxLength 512 */
  name: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /** @format date-time */
  started_date?: string | null;
  /** @format date-time */
  to_be_audited_date?: string | null;
  /** @format date-time */
  to_be_reviewed_date?: string | null;
  /** @format date-time */
  to_be_confirmed_date?: string | null;
  /** @format date-time */
  confirmed_date?: string | null;
  /** @format date-time */
  declined_date?: string | null;
  /** @format date-time */
  submitted_date?: string | null;
  /** @format date-time */
  completed_date?: string | null;
  is_to_be_audited: boolean;
  is_submitted: boolean;
  status?: AssessmentStatusEnum;
  /** @format uuid */
  author_id: string | null;
  /** @format uuid */
  workflow_id: string;
}

export interface AssessmentUpdateAuditDurationInput {
  /** @min 0 */
  audit_duration: number | null;
}

export interface AssessmentUpdateInput {
  name?: string;
  module_cultivation_selections?: ModuleCultivationSelectionsUpdateInput[];
  client_identifiers?: string[];
  is_evidence_auto_attached?: boolean;
}

export interface AssessmentUpdateOutput {
  /** @format uuid */
  uuid: string;
  name: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /** @format date-time */
  started_date: string;
  /** @format date-time */
  to_be_audited_date: string;
  /** @format date-time */
  to_be_reviewed_date: string;
  /** @format date-time */
  to_be_confirmed_date: string;
  /** @format date-time */
  confirmed_date: string;
  /** @format date-time */
  declined_date: string;
  /** @format date-time */
  submitted_date: string;
  /** @format date-time */
  completed_date: string;
  is_to_be_audited: boolean;
  is_submitted: boolean;
  status: string;
  /** @format uuid */
  author_id: string;
  /** @format uuid */
  workflow_id: string;
  client_identifiers: string[];
}

export interface AssessmentUserPermissionsAnswersOutput {
  create: boolean;
  update: boolean;
  delete: boolean;
}

export interface AssessmentUserPermissionsAssessmentsOutput {
  create: boolean;
  update: boolean;
  delete: boolean;
}

export interface AssessmentUserPermissionsCommentsOutput {
  create: boolean;
  update: boolean;
  delete: boolean;
}

export interface AssessmentUserPermissionsConnectedAssessmentsOutput {
  create: boolean;
  update: boolean;
  delete: boolean;
}

export interface AssessmentUserPermissionsCorrectiveActionsOutput {
  create: boolean;
  update: boolean;
  delete: boolean;
}

export interface AssessmentUserPermissionsEvidenceOutput {
  create: boolean;
  update: boolean;
  delete: boolean;
}

export interface AssessmentUserPermissionsOutput {
  answers?: AssessmentUserPermissionsAnswersOutput;
  assessments?: AssessmentUserPermissionsAssessmentsOutput;
  comments?: AssessmentUserPermissionsCommentsOutput;
  connected_assessments?: AssessmentUserPermissionsConnectedAssessmentsOutput;
  evidence?: AssessmentUserPermissionsEvidenceOutput;
  preliminary_questions?: AssessmentUserPermissionsPreliminaryQuestionsOutput;
  requests?: AssessmentUserPermissionsRequestsOutput;
  shortcomings?: AssessmentUserPermissionsShortcomingsOutput;
  corrective_actions?: AssessmentUserPermissionsCorrectiveActionsOutput;
}

export interface AssessmentUserPermissionsPreliminaryQuestionsOutput {
  create: boolean;
  update: boolean;
  delete: boolean;
}

export interface AssessmentUserPermissionsRequestsOutput {
  create: boolean;
  update: boolean;
  delete: boolean;
}

export interface AssessmentUserPermissionsShortcomingsOutput {
  create: boolean;
  update: boolean;
  delete: boolean;
}

export interface CamundaProcessDefinitionListOutputItem {
  /** @format uuid */
  uuid?: string;
  /** @format date-time */
  created_at: string;
  workflow_name: string;
  /** @format uuid */
  workflow_id: string;
  /** @maxLength 512 */
  process_definition_name: string;
  /** @maxLength 512 */
  process_definition_key: string | null;
  /** @maxLength 512 */
  process_definition_id: string;
  /**
   * @min 0
   * @max 2147483647
   */
  process_definition_version: number;
  roles: string[];
}

export interface CamundaProcessInstanceByAssessmentIdDetailOutput {
  /** @format uuid */
  uuid: string;
  process_definition_id: string;
  /** @format uuid */
  assessment_id: string;
}

export interface CamundaProcessInstanceCreateInput {
  process_definition_id: string;
  /** @format uuid */
  assessment_id: string;
}

export interface CamundaProcessInstanceCreateOutput {
  uuid: string;
  process_definition_id: string;
  /** @format uuid */
  assessment_id: string;
}

export interface CamundaProcessInstanceListOutputItem {
  uuid: string;
  process_definition_id: string;
  /** @format uuid */
  assessment_id: string;
}

export interface CamundaTaskCompleteInput {
  new_task_slug?: string;
  /** @format uuid */
  assessment_id: string;
  completion_arguments?: Record<string, any>;
}

export interface CamundaTaskCompleteOutput {
  uuid: string;
  /** @format date-time */
  created_at: string;
  camunda_user_id: string;
  name: string;
  slug: string;
  process_definition_id: string;
  process_instance_id: string;
  task_variables?: TaskVariablesCamundaTaskComplete;
  process_instance_variables?: ProcessInstanceVariablesCamundaTaskComplete;
}

export interface CamundaTaskDefinitionListOutputItem {
  name: string;
  slug: string;
}

export interface CamundaTaskDetailOutput {
  uuid: string;
  /** @format date-time */
  created_at: string;
  camunda_user_id: string;
  name: string;
  slug: string;
  process_definition_id: string;
  process_instance_id: string;
  task_variables?: TaskVariablesCamundaTaskDetail;
  process_instance_variables?: ProcessInstanceVariablesCamundaTaskDetail;
}

export interface CamundaTaskListOutputItem {
  /** @format uuid */
  uuid: string;
  /** @format date-time */
  created_at: string;
  camunda_user_id: string;
  name: string;
  slug: string;
  /** @format uuid */
  process_definition_id: string;
  /** @format uuid */
  process_instance_id: string;
}

export interface CamundaUserCreateOutput {
  /** @maxLength 16 */
  camunda_user_id?: string;
  /** @maxLength 128 */
  password: string;
  /** @format uuid */
  user_profile_id: string;
}

export enum CategoryEvidenceTypeStatusEnum {
  Completed = 'completed',
  PartiallyCompleted = 'partially_completed',
  NotCompleted = 'not_completed',
}

export interface CategoryListOutputItem {
  /** @format uuid */
  uuid: string;
  name: string;
  /** @format uuid */
  parent_category_id: string;
  taxonomic_level: TaxonomicLevelEnum;
  itc_identifier: number | null;
}

export interface CertificationBodyAssessmentMetadataCreateInput {
  /** @format uuid */
  assessment_id: string;
  farm_name: string;
  farm_address: string;
  farm_country: CountryEnum;
  farm_city: string;
  farm_postal_code: string;
}

export interface CertificationBodyAssessmentMetadataCreateOutput {
  /** @format uuid */
  uuid?: string;
  /** @format uuid */
  assessment_id: string;
  /** @maxLength 256 */
  farm_name: string;
  /** @maxLength 256 */
  farm_address: string;
  farm_country: CountryEnum;
  /** @maxLength 128 */
  farm_city: string;
  /** @maxLength 64 */
  farm_postal_code: string;
}

export interface CertificationBodyAssessmentMetadataDetailOutput {
  /** @format uuid */
  uuid?: string;
  /** @format uuid */
  assessment_id: string;
  /** @maxLength 256 */
  farm_name: string;
  /** @maxLength 256 */
  farm_address: string;
  farm_country: CountryEnum;
  /** @maxLength 128 */
  farm_city: string;
  /** @maxLength 64 */
  farm_postal_code: string;
}

export interface CertificationBodyAssessmentMetadataListOutputItem {
  /** @format uuid */
  uuid?: string;
  /** @format uuid */
  assessment_id: string;
  /** @maxLength 256 */
  farm_name: string;
  /** @maxLength 256 */
  farm_address: string;
  farm_country: CountryEnum;
  /** @maxLength 128 */
  farm_city: string;
  /** @maxLength 64 */
  farm_postal_code: string;
}

export interface CertificationBodyAssessmentMetadataUpdateInput {
  farm_name?: string;
  farm_address?: string;
  farm_country?: CountryEnum;
  farm_city?: string;
  farm_postal_code?: string;
}

export interface CertificationBodyAssessmentMetadataUpdateOutput {
  /** @format uuid */
  uuid?: string;
  /** @format uuid */
  assessment_id: string;
  /** @maxLength 256 */
  farm_name: string;
  /** @maxLength 256 */
  farm_address: string;
  farm_country: CountryEnum;
  /** @maxLength 128 */
  farm_city: string;
  /** @maxLength 64 */
  farm_postal_code: string;
}

export interface CertificationBodyListOutputItem {
  /** @format uuid */
  uuid?: string;
  /** @maxLength 256 */
  name: string;
  country: CountryEnum;
  type: string;
  /**
   * @format email
   * @maxLength 256
   */
  document_share_email?: string | null;
}

export interface ComplianceReportOutput {
  compliance_indication_explanation: string;
  compliance_indication?: ComplianceIndication;
  criticality_data?: CriticalityData[];
}

export enum ComplianceStatusEnum {
  Compliant = 'compliant',
  NotCompliant = 'not_compliant',
  NotApplicable = 'not_applicable',
  Answered = 'answered',
}

export enum ConfirmationStatusEnum {
  Confirmed = 'confirmed',
  Declined = 'declined',
}

export interface ConnectedAssessmentCreateInput {
  /** @format uuid */
  assessment_standard_request_id: string;
}

export interface ConnectedAssessmentCreateOutput {
  /** @format uuid */
  uuid?: string;
  /** @maxLength 512 */
  name: string;
  /** @format date-time */
  started_date?: string | null;
  /** @format date-time */
  completed_date?: string | null;
  /** @format date-time */
  modified_at: string;
  /** @format date-time */
  submitted_date?: string | null;
  is_submitted: boolean;
  status?: AssessmentStatusEnum;
}

export enum ControlPointAnswerTypeEnum {
  MultipleChoiceFarmLevelDropdown = 'multiple_choice_farm_level_dropdown',
  MultipleChoiceCultivationLevelDropdown = 'multiple_choice_cultivation_level_dropdown',
  MultipleChoiceCultivationLevelBulkDropdown = 'multiple_choice_cultivation_level_bulk_dropdown',
  SingleChoiceFarmLevelDropdown = 'single_choice_farm_level_dropdown',
  SingleChoiceFarmLevelButtonGroup = 'single_choice_farm_level_button_group',
  SingleChoiceCultivationLevelDropdown = 'single_choice_cultivation_level_dropdown',
  SingleChoiceCultivationLevelBulkDropdown = 'single_choice_cultivation_level_bulk_dropdown',
  SingleChoiceCultivationLevelButtonGroup = 'single_choice_cultivation_level_button_group',
  SingleChoiceCultivationLevelBulkButtonGroup = 'single_choice_cultivation_level_bulk_button_group',
  FarmLevelInputString = 'farm_level_input_string',
  CultivationLevelInputString = 'cultivation_level_input_string',
  CultivationLevelBulkInputString = 'cultivation_level_bulk_input_string',
  FarmLevelInputNumber = 'farm_level_input_number',
  CultivationLevelInputNumber = 'cultivation_level_input_number',
  CultivationLevelBulkInputNumber = 'cultivation_level_bulk_input_number',
  FarmLevelInputDate = 'farm_level_input_date',
  CultivationLevelInputDate = 'cultivation_level_input_date',
  CultivationLevelBulkInputDate = 'cultivation_level_bulk_input_date',
}

export interface ControlPointCriticalityDetailOutput {
  /** @format uuid */
  uuid?: string;
  /** @maxLength 512 */
  name: string;
  /** @maxLength 1024 */
  slug: string;
  standard_name: string;
  /** @format uuid */
  standard_id: string;
}

export interface ControlPointCriticalityListOutputItem {
  /** @format uuid */
  uuid?: string;
  /** @maxLength 512 */
  name: string;
  /** @maxLength 1024 */
  slug: string;
  standard_name: string;
  /** @format uuid */
  standard_id: string;
  priority: number;
}

export interface ControlPointDetailOutput {
  /** @format uuid */
  uuid: string;
  code: string;
  display_code: string | null;
  criteria: string | null;
  guidance: string | null;
  help_text: string | null;
  justification_guideline: string | null;
  main_text: string;
  version: string;
  /** @format uuid */
  criticality_id: string | null;
  /** @format uuid */
  module_id: string;
  /** @format uuid */
  possible_answer_set_id: string | null;
  /** @format uuid */
  scope_id: string | null;
  verification_method_ids: string[];
  category_ids: string[];
  is_multiple_choice: boolean;
  is_scored: boolean;
  answer_automatically: boolean;
  /** @format uuid */
  preferred_answer_id: string | null;
  control_point_index: number;
  duplicate_control_point_ids: string[];
  level: LevelEnum;
  allows_bulk_cultivation_answers: boolean;
  production_technique_ids: string[];
  is_evidence_shared_with_standard_setter: boolean;
  show_control_point_level: boolean;
  /** @format uuid */
  agriplace_criticality_id: string;
  logo: SimpleFile | null;
}

export interface ControlPointInputDetailOutput {
  /** @format uuid */
  uuid?: string;
  /** @format uuid */
  control_point_id: string;
  /** @maxLength 255 */
  name: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  is_editable?: boolean;
  /** @format uuid */
  possible_answer_set_id: string;
  /**
   * @min 0
   * @max 2147483647
   */
  index?: number;
  is_answer?: boolean;
}

export interface ControlPointInputListOutputItem {
  /** @format uuid */
  uuid: string;
  /** @format uuid */
  control_point_id: string;
  name: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  is_editable: boolean;
  /** @format uuid */
  possible_answer_set_id: string;
  index: number;
  is_answer: boolean;
}

export interface ControlPointListOutputItem {
  /** @format uuid */
  uuid: string;
  code: string;
  display_code: string | null;
  criteria: string | null;
  guidance: string | null;
  help_text: string | null;
  justification_guideline: string | null;
  main_text: string;
  version: string;
  /** @format uuid */
  criticality_id: string | null;
  /** @format uuid */
  module_id: string;
  /** @format uuid */
  possible_answer_set_id: string | null;
  /** @format uuid */
  scope_id: string | null;
  verification_method_ids: string[];
  is_multiple_choice: boolean;
  is_scored: boolean;
  answer_automatically: boolean;
  /** @format uuid */
  preferred_answer_id: string | null;
  control_point_index: number;
  duplicate_control_point_ids: string[];
  level: LevelEnum;
  allows_bulk_cultivation_answers: boolean;
  is_evidence_shared_with_standard_setter: boolean;
  show_control_point_level: boolean;
  /** @format uuid */
  agriplace_criticality_id: string;
}

export interface ControlPointProductionTechniqueDetailOutput {
  /** @format uuid */
  uuid?: string;
  /** @maxLength 512 */
  name: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /** @maxLength 1024 */
  slug: string;
  /**
   * @min 0
   * @max 2147483647
   */
  production_technique_index?: number;
}

export interface ControlPointProductionTechniqueListOutputItem {
  /** @format uuid */
  uuid?: string;
  /** @maxLength 512 */
  name: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /** @maxLength 1024 */
  slug: string;
  /**
   * @min 0
   * @max 2147483647
   */
  production_technique_index?: number;
}

export interface ControlPointVerificationMethodDetailOutput {
  /** @format uuid */
  uuid?: string;
  /** @maxLength 512 */
  name: string;
  /** @maxLength 1024 */
  slug: string;
  /** @maxLength 1024 */
  description?: string | null;
}

export interface ControlPointVerificationMethodListOutputItem {
  /** @format uuid */
  uuid?: string;
  /** @maxLength 512 */
  name: string;
  /** @maxLength 1024 */
  slug: string;
  /** @maxLength 1024 */
  description?: string | null;
}

export enum CountryEnum {
  AF = 'AF',
  AX = 'AX',
  AL = 'AL',
  DZ = 'DZ',
  AS = 'AS',
  AD = 'AD',
  AO = 'AO',
  AI = 'AI',
  AQ = 'AQ',
  AG = 'AG',
  AR = 'AR',
  AM = 'AM',
  AW = 'AW',
  AU = 'AU',
  AT = 'AT',
  AZ = 'AZ',
  BS = 'BS',
  BH = 'BH',
  BD = 'BD',
  BB = 'BB',
  BY = 'BY',
  BE = 'BE',
  BZ = 'BZ',
  BJ = 'BJ',
  BM = 'BM',
  BT = 'BT',
  BO = 'BO',
  BQ = 'BQ',
  BA = 'BA',
  BW = 'BW',
  BV = 'BV',
  BR = 'BR',
  IO = 'IO',
  BN = 'BN',
  BG = 'BG',
  BF = 'BF',
  BI = 'BI',
  CV = 'CV',
  KH = 'KH',
  CM = 'CM',
  CA = 'CA',
  KY = 'KY',
  CF = 'CF',
  TD = 'TD',
  CL = 'CL',
  CN = 'CN',
  CX = 'CX',
  CC = 'CC',
  CO = 'CO',
  KM = 'KM',
  CG = 'CG',
  CD = 'CD',
  CK = 'CK',
  CR = 'CR',
  CI = 'CI',
  HR = 'HR',
  CU = 'CU',
  CW = 'CW',
  CY = 'CY',
  CZ = 'CZ',
  DK = 'DK',
  DJ = 'DJ',
  DM = 'DM',
  DO = 'DO',
  EC = 'EC',
  EG = 'EG',
  SV = 'SV',
  GQ = 'GQ',
  ER = 'ER',
  EE = 'EE',
  SZ = 'SZ',
  ET = 'ET',
  FK = 'FK',
  FO = 'FO',
  FJ = 'FJ',
  FI = 'FI',
  FR = 'FR',
  GF = 'GF',
  PF = 'PF',
  TF = 'TF',
  GA = 'GA',
  GM = 'GM',
  GE = 'GE',
  DE = 'DE',
  GH = 'GH',
  GI = 'GI',
  GR = 'GR',
  GL = 'GL',
  GD = 'GD',
  GP = 'GP',
  GU = 'GU',
  GT = 'GT',
  GG = 'GG',
  GN = 'GN',
  GW = 'GW',
  GY = 'GY',
  HT = 'HT',
  HM = 'HM',
  VA = 'VA',
  HN = 'HN',
  HK = 'HK',
  HU = 'HU',
  IS = 'IS',
  IN = 'IN',
  ID = 'ID',
  IR = 'IR',
  IQ = 'IQ',
  IE = 'IE',
  IM = 'IM',
  IL = 'IL',
  IT = 'IT',
  JM = 'JM',
  JP = 'JP',
  JE = 'JE',
  JO = 'JO',
  KZ = 'KZ',
  KE = 'KE',
  KI = 'KI',
  KW = 'KW',
  KG = 'KG',
  LA = 'LA',
  LV = 'LV',
  LB = 'LB',
  LS = 'LS',
  LR = 'LR',
  LY = 'LY',
  LI = 'LI',
  LT = 'LT',
  LU = 'LU',
  MO = 'MO',
  MG = 'MG',
  MW = 'MW',
  MY = 'MY',
  MV = 'MV',
  ML = 'ML',
  MT = 'MT',
  MH = 'MH',
  MQ = 'MQ',
  MR = 'MR',
  MU = 'MU',
  YT = 'YT',
  MX = 'MX',
  FM = 'FM',
  MD = 'MD',
  MC = 'MC',
  MN = 'MN',
  ME = 'ME',
  MS = 'MS',
  MA = 'MA',
  MZ = 'MZ',
  MM = 'MM',
  NA = 'NA',
  NR = 'NR',
  NP = 'NP',
  NL = 'NL',
  NC = 'NC',
  NZ = 'NZ',
  NI = 'NI',
  NE = 'NE',
  NG = 'NG',
  NU = 'NU',
  NF = 'NF',
  KP = 'KP',
  MK = 'MK',
  MP = 'MP',
  NO = 'NO',
  OM = 'OM',
  PK = 'PK',
  PW = 'PW',
  PS = 'PS',
  PA = 'PA',
  PG = 'PG',
  PY = 'PY',
  PE = 'PE',
  PH = 'PH',
  PN = 'PN',
  PL = 'PL',
  PT = 'PT',
  PR = 'PR',
  QA = 'QA',
  RE = 'RE',
  RO = 'RO',
  RU = 'RU',
  RW = 'RW',
  BL = 'BL',
  SH = 'SH',
  KN = 'KN',
  LC = 'LC',
  MF = 'MF',
  PM = 'PM',
  VC = 'VC',
  WS = 'WS',
  SM = 'SM',
  ST = 'ST',
  SA = 'SA',
  SN = 'SN',
  RS = 'RS',
  SC = 'SC',
  SL = 'SL',
  SG = 'SG',
  SX = 'SX',
  SK = 'SK',
  SI = 'SI',
  SB = 'SB',
  SO = 'SO',
  ZA = 'ZA',
  GS = 'GS',
  KR = 'KR',
  SS = 'SS',
  ES = 'ES',
  LK = 'LK',
  SD = 'SD',
  SR = 'SR',
  SJ = 'SJ',
  SE = 'SE',
  CH = 'CH',
  SY = 'SY',
  TW = 'TW',
  TJ = 'TJ',
  TZ = 'TZ',
  TH = 'TH',
  TL = 'TL',
  TG = 'TG',
  TK = 'TK',
  TO = 'TO',
  TT = 'TT',
  TN = 'TN',
  TR = 'TR',
  TM = 'TM',
  TC = 'TC',
  TV = 'TV',
  UG = 'UG',
  UA = 'UA',
  AE = 'AE',
  GB = 'GB',
  UM = 'UM',
  US = 'US',
  UY = 'UY',
  UZ = 'UZ',
  VU = 'VU',
  VE = 'VE',
  VN = 'VN',
  VG = 'VG',
  VI = 'VI',
  WF = 'WF',
  EH = 'EH',
  YE = 'YE',
  ZM = 'ZM',
  ZW = 'ZW',
}

export interface CultivationBonusPointsPerThemeOutputItem {
  /** @format uuid */
  organization_cultivation_id: string;
  organization_cultivation_name: string;
  themes?: CultivationThemes[];
  /** @format double */
  all_themes_point_sum: number;
}

export interface EvidenceBulkArchiveInput {
  evidence_ids: string[];
}

export interface EvidenceBulkExpireInput {
  evidence_ids: string[];
}

export interface EvidenceCreateInput {
  name: string;
  /** @format date-time */
  expiry_date: string;
  /** @format uri */
  file: string;
  category_ids?: string[];
  organization_cultivation_ids?: string[];
  organization_plot_ids?: string[];
  product_ids?: string[];
  /** @format uuid */
  evidence_type_id?: string;
  client_identifiers?: string[];
  is_draft?: boolean;
  /** @format uuid */
  source_help_document_id?: string;
  /** @format uuid */
  source_evidence_id?: string;
}

export interface EvidenceCreateOutput {
  /** @format uuid */
  uuid?: string;
  /** @maxLength 512 */
  name: string;
  /** @format date-time */
  expiry_date?: string | null;
  file: SimpleFile | null;
  category_ids: string[];
  /** @format uuid */
  author_id: string;
  /** @format uuid */
  evidence_type_id: string | null;
  organization_cultivation_ids: string[];
  organization_plot_ids: string[];
  product_ids: string[];
  client_identifiers?: ClientIdentifierOrganizationEvidenceCreateOutputItem[];
  /** @format date-time */
  signed_date?: string | null;
  is_draft?: boolean;
  requires_signature: boolean;
  /** @format uuid */
  source_help_document_id: string | null;
  /** @format uuid */
  source_evidence_id: string | null;
}

export interface EvidenceDetailOutput {
  /** @format uuid */
  uuid?: string;
  /** @maxLength 512 */
  name: string;
  /** @format date-time */
  expiry_date?: string | null;
  file: SimpleFile | null;
  /** @format uuid */
  author_id: string;
  category_ids: string[];
  /** @format uuid */
  evidence_type_id: string | null;
  organization_cultivation_ids: string[];
  organization_plot_ids: string[];
  product_ids: string[];
  client_identifiers?: ClientIdentifierOrganizationEvidenceDetailOutputItem[];
  /** @default [] */
  farmer_control_point_ids?: string[];
  /** @format date-time */
  signed_date?: string | null;
  is_draft?: boolean;
  requires_signature: boolean;
  /** @format uuid */
  source_help_document_id: string | null;
  /** @format uuid */
  source_evidence_id: string | null;
  is_edited_online: boolean;
  is_saved_by_webhook?: boolean;
}

export interface EvidenceListOutputItem {
  /** @format uuid */
  uuid: string;
  name: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  expiry_date: string;
  /** @format uuid */
  author_id: string;
  /** @format uuid */
  evidence_type_id: string;
  category_ids: string[];
  client_identifiers?: ClientIdentifierOrganizationEvidenceListOutputItem[];
  /** @format date-time */
  signed_date: string;
  /** @format uuid */
  source_help_document_id: string;
  file: SimpleFile | null;
  /** @format uuid */
  source_evidence_id: string;
  is_expired: boolean;
  is_archived: boolean;
}

export interface EvidenceOnlyOfficeCallbackOutput {
  error: number;
}

export interface EvidenceTypeDetailOutput {
  /** @format uuid */
  uuid?: string;
  /** @maxLength 512 */
  name: string;
  /** @pattern ^[-a-zA-Z0-9_]+$ */
  slug: string;
}

export interface EvidenceTypeListOutputItem {
  /** @format uuid */
  uuid?: string;
  /** @maxLength 512 */
  name: string;
  /** @pattern ^[-a-zA-Z0-9_]+$ */
  slug: string;
}

export interface EvidenceUpdateInput {
  name?: string;
  /** @format date-time */
  expiry_date?: string | null;
  /** @format uri */
  file?: string;
  /** @format uuid */
  evidence_type_id?: string;
  category_ids?: (string | null)[];
  organization_cultivation_ids?: (string | null)[];
  organization_plot_ids?: (string | null)[];
  product_ids?: (string | null)[];
  client_identifiers?: string[];
  is_draft?: boolean;
  /** @format date-time */
  signed_at?: string;
}

export interface EvidenceUpdateOutput {
  /** @format uuid */
  uuid?: string;
  /** @maxLength 512 */
  name: string;
  /** @format date-time */
  expiry_date?: string | null;
  file: SimpleFile | null;
  /** @format uuid */
  author_id: string;
  category_ids: string[];
  /** @format uuid */
  evidence_type_id: string | null;
  organization_cultivation_ids: string[];
  organization_plot_ids: string[];
  product_ids: string[];
  client_identifiers?: ClientIdentifierOrganizationEvidenceUpdateOutputItem[];
  /** @format date-time */
  signed_date?: string | null;
  is_draft?: boolean;
  requires_signature: boolean;
  /** @format uuid */
  source_help_document_id: string | null;
}

export interface ExternalMembershipListOutputItem {
  /** @format uuid */
  uuid?: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /** @format uuid */
  organization_id: string;
  membership_type: MembershipTypeEnum;
  membership_data?: Record<string, any>;
}

export enum GroupEnum {
  BioSoilCrop = 'bio_soil_crop',
  Other = 'other',
}

export interface HelpDocumentDetailOutput {
  /** @format uuid */
  uuid: string;
  name: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /** @format date-time */
  expiry_date: string;
  /** @format uuid */
  organization_id: string;
  control_point_ids: string[];
  evidence_type_ids: string[];
  category_ids: string[];
  /** @format uuid */
  help_document_type_id: string;
  tenant_id: string;
  file?: HelpDocumentFileDetail;
}

export interface HelpDocumentListOutputItem {
  /** @format uuid */
  uuid: string;
  name: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /** @format date-time */
  expiry_date: string;
  organization_name: string;
  /** @format uuid */
  help_document_type_id: string;
  tenant_id: string;
  file?: HelpDocumentFileList;
  is_evidence_form: boolean;
}

export interface HelpDocumentTypeDetailOutput {
  /** @format uuid */
  uuid?: string;
  /** @maxLength 512 */
  name: string;
  /** @pattern ^[-a-zA-Z0-9_]+$ */
  slug: string;
}

export interface HelpDocumentTypeListOutputItem {
  /** @format uuid */
  uuid?: string;
  /** @maxLength 512 */
  name: string;
  /** @pattern ^[-a-zA-Z0-9_]+$ */
  slug: string;
}

export interface HomologaActiveGroupDetailOutput {
  /** @format uuid */
  uuid?: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /** @maxLength 512 */
  code: string;
  /** @maxLength 512 */
  name: string;
}

export interface HomologaActiveGroupListOutputItem {
  /** @format uuid */
  uuid?: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /** @maxLength 512 */
  code: string;
  /** @maxLength 512 */
  name: string;
}

export interface HomologaActiveIndicationDetailOutput {
  /** @format uuid */
  uuid?: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /** @maxLength 512 */
  code: string;
  /** @maxLength 512 */
  name: string;
}

export interface HomologaActiveIndicationListOutputItem {
  /** @format uuid */
  uuid?: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /** @maxLength 512 */
  code: string;
  /** @maxLength 512 */
  name: string;
}

export interface HomologaActiveIngredientDetailOutput {
  /** @format uuid */
  uuid?: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /** @maxLength 512 */
  code: string;
  /** @maxLength 512 */
  name: string;
  /** @format uuid */
  active_group?: string | null;
  /** @format uuid */
  active_type?: string | null;
  /** @format uuid */
  mode_of_action?: string | null;
  /** @format uuid */
  active_indication?: string | null;
}

export interface HomologaActiveIngredientListOutputItem {
  /** @format uuid */
  uuid?: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /** @maxLength 512 */
  code: string;
  /** @maxLength 512 */
  name: string;
  /** @format uuid */
  active_group?: string | null;
  /** @format uuid */
  active_type?: string | null;
  /** @format uuid */
  mode_of_action?: string | null;
  /** @format uuid */
  active_indication?: string | null;
}

export interface HomologaActiveTypeDetailOutput {
  /** @format uuid */
  uuid?: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /** @maxLength 512 */
  code: string;
  /** @maxLength 512 */
  name: string;
}

export interface HomologaActiveTypeListOutputItem {
  /** @format uuid */
  uuid?: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /** @maxLength 512 */
  code: string;
  /** @maxLength 512 */
  name: string;
}

export interface HomologaModeOfActionDetailOutput {
  /** @format uuid */
  uuid?: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /** @maxLength 512 */
  code: string;
  /** @maxLength 512 */
  name: string;
}

export interface HomologaModeOfActionListOutputItem {
  /** @format uuid */
  uuid?: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /** @maxLength 512 */
  code: string;
  /** @maxLength 512 */
  name: string;
}

export enum InspectionTypeEnum {
  Connected = 'connected',
  NonConnected = 'non-connected',
}

export enum InvitationStatusEnum {
  Pending = 'pending',
  Active = 'active',
  Deactivated = 'deactivated',
  Expired = 'expired',
  NotAvaliable = 'not_avaliable',
  Rejected = 'rejected',
}

export interface InviteeOrganizationInvitationAcceptOutput {
  /** @format uuid */
  uuid?: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /** @format date-time */
  expires_at?: string;
  tenant_id: string;
  /** @format uuid */
  inviter_id: string;
  /**
   * @format email
   * @maxLength 256
   */
  invitee: string;
  is_accepted?: boolean;
  is_expired: boolean;
  is_cancelled?: boolean;
  is_rejected?: boolean;
  roles: string[];
  status: InvitationStatusEnum;
  /** @maxLength 256 */
  name?: string | null;
}

export interface InviteeOrganizationInvitationDetailOutput {
  /** @format uuid */
  uuid?: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /** @format date-time */
  expires_at?: string;
  tenant_id: string;
  /** @format uuid */
  inviter_id: string;
  /**
   * @format email
   * @maxLength 256
   */
  invitee: string;
  is_accepted?: boolean;
  is_expired: boolean;
  is_cancelled?: boolean;
  is_rejected?: boolean;
  roles: string[];
  status: InvitationStatusEnum;
  /** @maxLength 256 */
  name?: string | null;
}

export interface InviteeOrganizationInvitationListOutputItem {
  /** @format uuid */
  uuid?: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /** @format date-time */
  expires_at?: string;
  tenant_id: string;
  /** @format uuid */
  inviter_id: string;
  inviter_name: string;
  /**
   * @format email
   * @maxLength 256
   */
  invitee: string;
  is_accepted?: boolean;
  is_expired: boolean;
  is_cancelled?: boolean;
  is_rejected?: boolean;
  roles: string[];
  status: InvitationStatusEnum;
  /** @maxLength 256 */
  name?: string | null;
  role_labels: string[];
}

export interface InviteeOrganizationInvitationRejectOutput {
  /** @format uuid */
  uuid?: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /** @format date-time */
  expires_at?: string;
  tenant_id: string;
  /** @format uuid */
  inviter_id: string;
  /**
   * @format email
   * @maxLength 256
   */
  invitee: string;
  is_accepted?: boolean;
  is_expired: boolean;
  is_cancelled?: boolean;
  is_rejected?: boolean;
  roles: string[];
  status: InvitationStatusEnum;
  /** @maxLength 256 */
  name?: string | null;
}

export interface InviterOrganizationInvitationCreateInput {
  /** @format email */
  invitee: string;
  roles: RolesWithoutOwnerEnum[];
  /** @maxLength 256 */
  name: string;
}

export interface InviterOrganizationInvitationCreateOutput {
  /** @format uuid */
  uuid?: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /** @format date-time */
  expires_at?: string;
  tenant_id: string;
  /** @format uuid */
  inviter_id: string;
  /**
   * @format email
   * @maxLength 256
   */
  invitee: string;
  is_accepted?: boolean;
  is_expired: boolean;
  is_cancelled?: boolean;
  is_rejected?: boolean;
  roles: string[];
  status: InvitationStatusEnum;
  /** @maxLength 256 */
  name?: string | null;
}

export interface InviterOrganizationInvitationDetailOutput {
  /** @format uuid */
  uuid?: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /** @format date-time */
  expires_at?: string;
  tenant_id: string;
  /** @format uuid */
  inviter_id: string;
  /**
   * @format email
   * @maxLength 256
   */
  invitee: string;
  is_accepted?: boolean;
  is_expired: boolean;
  is_cancelled?: boolean;
  is_rejected?: boolean;
  roles: string[];
  status: InvitationStatusEnum;
  /** @maxLength 256 */
  name?: string | null;
}

export interface InviterOrganizationInvitationListOutputItem {
  /** @format uuid */
  uuid?: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /** @format date-time */
  expires_at?: string;
  tenant_id: string;
  /** @format uuid */
  inviter_id: string;
  inviter_name: string;
  /**
   * @format email
   * @maxLength 256
   */
  invitee: string;
  is_accepted?: boolean;
  is_expired: boolean;
  is_cancelled?: boolean;
  is_rejected?: boolean;
  roles: string[];
  status: InvitationStatusEnum;
  /** @maxLength 256 */
  name?: string | null;
  role_labels: string[];
  is_pending: boolean;
}

export interface InviterOrganizationInvitationUpdateInput {
  /** @format email */
  invitee?: string;
  roles?: RolesWithoutOwnerEnum[];
  /** @maxLength 256 */
  name?: string;
}

export interface InviterOrganizationInvitationUpdateOutput {
  /** @format uuid */
  uuid?: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /** @format date-time */
  expires_at?: string;
  tenant_id: string;
  /** @format uuid */
  inviter_id: string;
  /**
   * @format email
   * @maxLength 256
   */
  invitee: string;
  is_accepted?: boolean;
  is_expired: boolean;
  is_cancelled?: boolean;
  is_rejected?: boolean;
  roles: string[];
  status: InvitationStatusEnum;
  /** @maxLength 256 */
  name?: string | null;
}

export enum LevelEnum {
  Farm = 'farm',
  Cultivation = 'cultivation',
  Plot = 'plot',
}

export interface MalusComplensationPerCultivationOutputItem {
  /** @format uuid */
  organization_cultivation_id: string;
  organization_cultivation_name: string;
  /** @format double */
  total_malus_points: number;
  /** @format double */
  minimum_extra_bonus_points_to_score: number;
  bio_soil_crop_theme_bonus_points?: BioSoilCropThemeBonusPoints;
  /** @format double */
  other_theme_bonus_points: number;
  current_balance?: CurrentBalance;
}

export interface MeasurementCategoryDetailOutput {
  /** @format uuid */
  uuid?: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /** @maxLength 512 */
  name: string;
  /** @pattern ^[-a-zA-Z0-9_]+$ */
  slug: string;
  description?: string | null;
}

export interface MeasurementCategoryListOutputItem {
  /** @format uuid */
  uuid?: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /** @maxLength 512 */
  name: string;
  /** @pattern ^[-a-zA-Z0-9_]+$ */
  slug: string;
  description?: string | null;
}

export interface MeasurementDetailOutput {
  /** @format uuid */
  uuid?: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /** @format uuid */
  homologa_active_ingredient_id: string | null;
  /** @format uuid */
  measurement_category_id: string;
  /** @format uuid */
  agriplace_measurement_id: string | null;
  /** @format uuid */
  measurement_type_id: string | null;
}

export interface MeasurementListOutputItem {
  /** @format uuid */
  uuid?: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /** @format uuid */
  homologa_active_ingredient_id: string | null;
  /** @format uuid */
  measurement_category_id: string;
  /** @format uuid */
  agriplace_measurement_id: string | null;
  /** @format uuid */
  measurement_type_id: string | null;
}

export interface MeasurementTypeDetailOutput {
  /** @format uuid */
  uuid?: string;
  /** @pattern ^[-a-zA-Z0-9_]+$ */
  slug: string;
  /** @maxLength 128 */
  name: string;
}

export interface MeasurementTypeListOutputItem {
  /** @format uuid */
  uuid?: string;
  /** @pattern ^[-a-zA-Z0-9_]+$ */
  slug: string;
  /** @maxLength 128 */
  name: string;
}

export enum MembershipTypeEnum {
  LeafCharity = 'leaf_charity',
  LeafMarque = 'leaf_marque',
  CountyParishHolding = 'county_parish_holding',
}

export type MetaAssessmentPreliminaryQuestionAnswerBulkUpdate =
  | ({
      is_draft: 'false';
    } & AssessmentPreliminaryQuestionAnswerBulkUpdateOutput)
  | ({
      is_draft: 'true';
    } & AssessmentPreliminaryQuestionAnswerBulkUpdateDraftOutput);

export interface ModuleDetailOutput {
  /** @format uuid */
  uuid?: string;
  is_required_by_parent?: boolean;
  is_applicable_to_organization?: boolean;
  /** @maxLength 512 */
  name: string;
  /** @maxLength 2048 */
  description?: string | null;
  /** @format uuid */
  standard_id: string;
  product_ids: string[];
  /** @format uuid */
  parent_module_id: string;
  child_module_ids: string[];
  /** @maxLength 64 */
  version: string;
  control_point_ids: string[];
  module_index: number;
  uses_malus_points: boolean;
  /** @format uuid */
  unique_identifier_id: string;
  /** @format uuid */
  smk_theme_id: string | null;
}

export interface ModuleListOutputItem {
  /** @format uuid */
  uuid?: string;
  /** @maxLength 512 */
  name: string;
  index: number;
  /** @maxLength 2048 */
  description?: string | null;
  /** @format uuid */
  standard_id: string;
  logo: SimpleFile | null;
  small_logo: SimpleFile | null;
  uses_malus_points: boolean;
  uses_retailers: boolean;
  is_applicable_to_organization?: boolean;
  product_ids: string[];
  retailer_ids: string[];
}

export interface NonCompliantControlPointListOutputItem {
  /** @format uuid */
  uuid: string;
  /** @format uuid */
  control_point_id: string;
  /** @format uuid */
  criticality_id: string | null;
  /** @format uuid */
  possible_answer_set_id: string | null;
  /** @maxLength 2048 */
  main_text: string;
  /** @maxLength 128 */
  code: string;
  /** @maxLength 128 */
  display_code?: string | null;
  level?: LevelEnum;
  allows_bulk_cultivation_answers?: boolean;
  is_evidence_shared_with_standard_setter?: boolean;
  is_multiple_choice?: boolean;
  show_control_point_level?: boolean;
  standard_setter?: string;
  /** @format uuid */
  module_id: string;
  control_point_answer_type: ControlPointAnswerTypeEnum;
  is_scored?: boolean;
}

export enum OccurrenceEnum {
  First = 'first',
  Second = 'second',
}

export interface OrganizationClientIdentifierCreateInput {
  /** @format uuid */
  certification_body_id: string;
  standard_ids: string[];
  value: string;
}

export interface OrganizationClientIdentifierCreateOutput {
  /** @format uuid */
  uuid?: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /** @format uuid */
  organization_id: string | null;
  /** @maxLength 256 */
  value: string;
  standards?: OrganizationClientIdentifierCreateStandardOutput[];
  certification_body?: OrganizationClientIdentifierCreateCertificationBodyOutput;
  tenant_id: string;
}

export interface OrganizationClientIdentifierDetailOutputItem {
  /** @format uuid */
  uuid?: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /** @format uuid */
  organization_id: string | null;
  /** @maxLength 256 */
  value: string;
  standards?: OrganizationClientIdentifierDetailStandardOutput[];
  certification_body?: OrganizationClientIdentifierDetailCertificationBodyOutput;
  tenant_id: string;
}

export interface OrganizationClientIdentifierListOutputItem {
  /** @format uuid */
  uuid?: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /** @format uuid */
  organization_id: string | null;
  /** @maxLength 256 */
  value: string;
  standards?: OrganizationClientIdentifierListStandardOutput[];
  certification_body?: OrganizationClientIdentifierListCertificationBodyOutput;
  tenant_id: string;
}

export interface OrganizationClientIdentifierMoveStandardInput {
  /** @format uuid */
  new_certification_body_id: string;
  /** @format uuid */
  standard_id: string;
  value?: string;
}

export interface OrganizationClientIdentifierMoveStandardOutput {
  /** @format uuid */
  uuid?: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /** @format uuid */
  organization_id: string | null;
  /** @maxLength 256 */
  value: string;
  standards?: OrganizationClientIdentifierMoveStandardStandardOutputItem[];
  certification_body?: OrganizationClientIdentifierMoveCertificationBodyOutput;
  tenant_id: string;
}

export interface OrganizationClientIdentifierUpdateInput {
  /** @format uuid */
  certification_body_id?: string;
  standard_ids?: string[];
  value?: string;
}

export interface OrganizationClientIdentifierUpdateOutput {
  /** @format uuid */
  uuid?: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /** @format uuid */
  organization_id: string | null;
  /** @maxLength 256 */
  value: string;
  standards?: OrganizationClientIdentifierUpdateStandardOutput[];
  certification_body?: OrganizationClientIdentifierUpdateCertificationBodyOutput;
  tenant_id: string;
}

export interface OrganizationCreateInput {
  name: string;
  street1?: string;
  street2?: string;
  postal_code?: string;
  city?: string;
  region?: string;
  country: CountryEnum;
  phone_number?: string;
  email?: string;
  website?: string;
  is_organic?: boolean;
  uses_greenhouses?: boolean;
  /** @format uri */
  logo?: string;
  has_all_notifications_enabled?: boolean;
  has_new_request_notifications_enabled?: boolean;
  has_new_comment_notifications_enabled?: boolean;
  ggn?: string | null;
}

export interface OrganizationCreateOutput {
  /** @format uuid */
  uuid?: string;
  logo: SimpleFile | null;
  tenant_id: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /** @maxLength 256 */
  name: string;
  /** @maxLength 256 */
  street1?: string | null;
  /** @maxLength 256 */
  street2?: string | null;
  /** @maxLength 64 */
  postal_code?: string | null;
  /** @maxLength 128 */
  city?: string | null;
  /** @maxLength 128 */
  region?: string | null;
  country: CountryEnum;
  /** @maxLength 99 */
  phone_number?: string | null;
  is_organic?: boolean;
  uses_greenhouses?: boolean;
  /** @maxLength 256 */
  website?: string | null;
  /**
   * @format email
   * @maxLength 256
   */
  email?: string | null;
  is_paying_organization: boolean;
  logged_in_member_notification_settings: {
    has_all_notifications_enabled: boolean;
    has_new_comment_notifications_enabled: boolean;
    has_new_request_notifications_enabled: boolean;
  };
  camunda_workflows?: CamundaWorkflowsOrganizationCreate[];
  client_identifiers?: OrganizationClientIdentifiersOrganizationCreateOutputItem[];
  origin: string;
  type: string;
  /** @maxLength 256 */
  ggn?: string | null;
}

export interface OrganizationCultivationListOutputItem {
  /** @format uuid */
  uuid?: string;
  /** @maxLength 512 */
  name: string;
  product?: Product;
  /** @format date */
  season_start_date?: string | null;
  /** @format date */
  season_end_date?: string | null;
  production_technique?: ProductionTechniqueOrganizationCultivationList;
  client_identifiers?: ClientIdentifierOrganizationCultivationListOutputItem[];
}

export interface OrganizationDetailOutput {
  /** @format uuid */
  uuid?: string;
  logo: SimpleFile | null;
  tenant_id: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /** @maxLength 256 */
  name: string;
  /** @maxLength 256 */
  street1?: string | null;
  /** @maxLength 256 */
  street2?: string | null;
  /** @maxLength 64 */
  postal_code?: string | null;
  /** @maxLength 128 */
  city?: string | null;
  /** @maxLength 128 */
  region?: string | null;
  country: CountryEnum;
  /** @maxLength 99 */
  phone_number?: string | null;
  is_organic?: boolean;
  uses_greenhouses?: boolean;
  /** @maxLength 256 */
  website?: string | null;
  /**
   * @format email
   * @maxLength 256
   */
  email?: string | null;
  camunda_workflows?: CamundaWorkflowsOrganizationDetail[];
  is_paying_organization: boolean;
  logged_in_member_notification_settings: {
    has_all_notifications_enabled: boolean;
    has_new_comment_notifications_enabled: boolean;
    has_new_request_notifications_enabled: boolean;
  };
  client_identifiers?: OrganizationClientIdentifiersOrganizationDetailOutputItem[];
  origin: string;
  type: string;
  active_subscription_standards?: ActiveSubscriptionStandardsOrganizationDetail[];
  /** @format uuid */
  active_subscription_plan_id: string | null;
  /** @maxLength 256 */
  ggn?: string | null;
  /**
   * @format email
   * @maxLength 256
   */
  document_share_email?: string | null;
  retailer_ids: string[];
  can_create_assessment: boolean;
}

export interface OrganizationListOutputItem {
  /** @format uuid */
  uuid?: string;
  /** @maxLength 256 */
  name: string;
  country: CountryEnum;
  tenant_id: string;
  logged_in_member_notification_settings: {
    has_all_notifications_enabled: boolean;
    has_new_comment_notifications_enabled: boolean;
    has_new_request_notifications_enabled: boolean;
  };
  origin: string;
  type: string;
  /** @maxLength 256 */
  ggn?: string | null;
  /**
   * @format email
   * @maxLength 256
   */
  document_share_email?: string | null;
}

export interface OrganizationLogoUpdateInput {
  /** @format uri */
  logo: string;
}

export interface OrganizationLogoUpdateOutput {
  /** @format uuid */
  uuid?: string;
  logo: SimpleFile | null;
}

export interface OrganizationMemberCreateInput {
  /** @format email */
  email: string;
  roles: RolesWithoutOwnerEnum[];
  has_all_notifications_enabled?: boolean;
  has_new_comment_notifications_enabled?: boolean;
  has_new_request_notifications_enabled?: boolean;
}

export interface OrganizationMemberCreateOutput {
  /** @format uuid */
  uuid?: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /**
   * @format email
   * @maxLength 256
   */
  email: string;
  tenant_id: string;
  /** @format uuid */
  organization_id: string;
  /** @format uuid */
  user_profile_id: string | null;
  roles: string[];
  has_all_notifications_enabled?: boolean;
  has_new_comment_notifications_enabled?: boolean;
  has_new_request_notifications_enabled?: boolean;
  name: string | null;
}

export interface OrganizationMemberDetailOutput {
  /** @format uuid */
  uuid?: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /**
   * @format email
   * @maxLength 256
   */
  email: string;
  tenant_id: string;
  /** @format uuid */
  organization_id: string;
  /** @format uuid */
  user_profile_id: string | null;
  roles: string[];
  has_all_notifications_enabled?: boolean;
  has_new_comment_notifications_enabled?: boolean;
  has_new_request_notifications_enabled?: boolean;
  name: string | null;
}

export interface OrganizationMemberListOutputItem {
  /** @format uuid */
  uuid?: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /**
   * @format email
   * @maxLength 256
   */
  email: string;
  tenant_id: string;
  /** @format uuid */
  organization_id: string;
  /** @format uuid */
  user_profile_id: string | null;
  roles: string[];
  name: string | null;
  has_all_notifications_enabled?: boolean;
  has_new_comment_notifications_enabled?: boolean;
  has_new_request_notifications_enabled?: boolean;
  role_labels: string[];
  status: string;
}

export interface OrganizationMemberUpdateInput {
  roles?: RolesWithoutOwnerEnum[];
  has_all_notifications_enabled?: boolean;
  has_new_comment_notifications_enabled?: boolean;
  has_new_request_notifications_enabled?: boolean;
}

export interface OrganizationMemberUpdateOutput {
  /** @format uuid */
  uuid?: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /**
   * @format email
   * @maxLength 256
   */
  email: string;
  tenant_id: string;
  /** @format uuid */
  organization_id: string;
  /** @format uuid */
  user_profile_id: string | null;
  roles: string[];
  has_all_notifications_enabled?: boolean;
  has_new_comment_notifications_enabled?: boolean;
  has_new_request_notifications_enabled?: boolean;
  name: string | null;
}

export interface OrganizationPlotCultivationCreateInput {
  /** @format uuid */
  product_id: string;
  /** @format uuid */
  production_technique_id: string;
}

export interface OrganizationPlotCultivationCreateOutput {
  /** @format uuid */
  uuid?: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  organization_plot?: PlotCultivationCreateOrganizationPlot;
  product?: PlotCultivationCreateProduct;
  production_technique?: PlotCultivationCreateProductionTechnique;
}

export interface OrganizationPlotCultivationDetailOutput {
  /** @format uuid */
  uuid?: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  organization_plot?: PlotCultivationDetailOrganizationPlot;
  product?: PlotCultivationDetailProduct;
  production_technique?: PlotCultivationDetailProductionTechnique;
  can_be_edited: boolean;
  can_be_deleted: boolean;
}

export interface OrganizationPlotCultivationListOutputItem {
  /** @format uuid */
  uuid?: string;
  /** @maxLength 512 */
  name: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  product?: PlotCultivationListProduct;
  production_technique?: PlotCultivationListProductionTechnique;
  can_be_edited: boolean;
  can_be_deleted: boolean;
}

export interface OrganizationPlotCultivationUpdateInput {
  /** @format uuid */
  product_id?: string;
  /** @format uuid */
  production_technique_id?: string;
}

export interface OrganizationPlotCultivationUpdateOutput {
  /** @format uuid */
  uuid?: string;
  /** @maxLength 512 */
  name: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  product?: PlotCultivationUpdateProduct;
  production_technique?: PlotCultivationUpdateProductionTechnique;
}

export interface OrganizationUniqueIdentifierCreateInput {
  /** @format uuid */
  organization_id: string;
  /** @format uuid */
  unique_identifier_id: string;
  value: string;
}

export interface OrganizationUniqueIdentifierCreateOutput {
  /** @format uuid */
  uuid?: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /** @format uuid */
  organization_id: string;
  unique_identifier?: UniqueIdentifierOrganizationUniqueIdentifierCreate;
  /** @maxLength 256 */
  value?: string | null;
  tenant_id: string;
}

export interface OrganizationUniqueIdentifierDetailOutput {
  /** @format uuid */
  uuid?: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /** @format uuid */
  organization_id: string;
  unique_identifier?: UniqueIdentifierOrganizationUniqueIdentifierDetail;
  /** @maxLength 256 */
  value?: string | null;
  tenant_id: string;
}

export interface OrganizationUniqueIdentifierListOutputItem {
  /** @format uuid */
  uuid?: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /** @format uuid */
  organization_id: string;
  unique_identifier?: UniqueIdentifierOrganizationUniqueIdentifierList;
  /** @maxLength 256 */
  value?: string | null;
  tenant_id: string;
}

export interface OrganizationUniqueIdentifierUpdateInput {
  value?: string | null;
}

export interface OrganizationUniqueIdentifierUpdateOutput {
  /** @format uuid */
  uuid?: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /** @format uuid */
  organization_id: string;
  unique_identifier?: UniqueIdentifierOrganizationUniqueIdentifierUpdate;
  /** @maxLength 256 */
  value?: string | null;
  tenant_id: string;
}

export interface OrganizationUpdateInput {
  name?: string;
  street1?: string | null;
  street2?: string | null;
  postal_code?: string | null;
  city?: string | null;
  region?: string | null;
  country?: CountryEnum;
  phone_number?: string | null;
  /** @format email */
  email?: string | null;
  website?: string | null;
  is_organic?: boolean;
  uses_greenhouses?: boolean;
  has_all_notifications_enabled?: boolean;
  has_new_request_notifications_enabled?: boolean;
  has_new_comment_notifications_enabled?: boolean;
  ggn?: string | null;
  /** @format email */
  document_share_email?: string | null;
}

export interface OrganizationUpdateOutput {
  /** @format uuid */
  uuid?: string;
  tenant_id: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /** @maxLength 256 */
  name: string;
  /** @maxLength 256 */
  street1?: string | null;
  /** @maxLength 256 */
  street2?: string | null;
  /** @maxLength 64 */
  postal_code?: string | null;
  /** @maxLength 128 */
  city?: string | null;
  /** @maxLength 128 */
  region?: string | null;
  country: CountryEnum;
  /** @maxLength 99 */
  phone_number?: string | null;
  is_organic?: boolean;
  uses_greenhouses?: boolean;
  /** @maxLength 256 */
  website?: string | null;
  /**
   * @format email
   * @maxLength 256
   */
  email?: string | null;
  logo: SimpleFile | null;
  is_paying_organization: boolean;
  logged_in_member_notification_settings: {
    has_all_notifications_enabled: boolean;
    has_new_comment_notifications_enabled: boolean;
    has_new_request_notifications_enabled: boolean;
  };
  client_identifiers?: OrganizationClientIdentifiersOrganizationUpdateOutputItem[];
  origin: string;
  type: string;
  /** @maxLength 256 */
  ggn?: string | null;
  /**
   * @format email
   * @maxLength 256
   */
  document_share_email?: string | null;
}

export enum OriginEnum {
  Agriplace = 'agriplace',
  Smk = 'smk',
  Leaf = 'leaf',
}

export interface PaginatedAssessmentCategoryEvidenceTypeControlPointListOutput {
  pagination?: any;
  results?: AssessmentCategoryEvidenceTypeControlPointListOutputItem[];
}

export interface PaginatedAssessmentControlPointListOutput {
  pagination?: any;
  results?: AssessmentControlPointListOutputItem[];
}

export interface PaginatedStandardSetterAssessmentListOutput {
  pagination?: any;
  results?: StandardSetterAssessmentListOutputItem[];
}

export interface PaginatedStandardSetterEvidenceListOutput {
  pagination?: any;
  results?: StandardSetterEvidenceListOutputItem[];
}

export interface PaginatedV2AssessmentControlPointListOutput {
  pagination?: any;
  results?: V2AssessmentControlPointListOutputItem[];
}

export interface PossibleAnswerDetailOutput {
  /** @format uuid */
  uuid?: string;
  is_justification_required?: boolean;
  is_additional_detail_required?: boolean;
  /** @maxLength 1024 */
  main_text?: string | null;
  answer_type?: AnswerTypeEnum;
}

export interface PossibleAnswerListOutputItem {
  /** @format uuid */
  uuid?: string;
  is_justification_required?: boolean;
  is_additional_detail_required?: boolean;
  /** @maxLength 1024 */
  main_text?: string | null;
  answer_type?: AnswerTypeEnum;
}

export interface PossibleAnswerSetDetailOutput {
  /** @format uuid */
  uuid: string;
  name: string;
  ordered_possible_answers: {
    /** @format uuid */
    uuid: string;
    is_justification_required: boolean;
    is_additional_detail_required: boolean;
    /** @format uuid */
    possible_answer_unit_id: string | null;
    possible_answer_unit_symbol: string | null;
    compliance_status: string | null;
  }[];
}

export interface PossibleAnswerSetListOutputItem {
  /** @format uuid */
  uuid: string;
  name: string;
  ordered_possible_answers: {
    /** @format uuid */
    uuid: string;
    is_justification_required: boolean;
    is_additional_detail_required: boolean;
    /** @format uuid */
    possible_answer_unit_id: string | null;
    possible_answer_unit_symbol: string | null;
    compliance_status: string | null;
  }[];
}

export interface PossibleAnswerUnitDetailOutput {
  /** @format uuid */
  uuid?: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /** @maxLength 512 */
  symbol: string;
  /** @maxLength 512 */
  name: string;
  /** @pattern ^[-a-zA-Z0-9_]+$ */
  slug: string;
  /** @maxLength 512 */
  synonym?: string | null;
}

export interface PossibleAnswerUnitListOutputItem {
  /** @format uuid */
  uuid?: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /** @maxLength 512 */
  symbol: string;
  /** @maxLength 512 */
  name: string;
  /** @pattern ^[-a-zA-Z0-9_]+$ */
  slug: string;
  /** @maxLength 512 */
  synonym?: string | null;
}

export interface PreliminaryQuestionListOutputItem {
  /** @format uuid */
  uuid: string;
  main_text: string;
  name: string;
  slug: string;
  /** @format uuid */
  possible_answer_set_id: string;
  standard_names: string[];
  answer_type: string;
  description: string;
  /** @format double */
  max_answer_value: number;
  /** @format double */
  min_answer_value: number;
  max_decimal_places: number;
  category_name: string | null;
}

export interface ProductDetailOutput {
  /** @format uuid */
  uuid?: string;
  /** @maxLength 1000 */
  latin_name?: string | null;
  /** @maxLength 256 */
  name: string;
  /** @maxLength 256 */
  planet_proof_name?: string | null;
  /** @format uuid */
  product_group?: string | null;
}

export interface ProductGroupListOutputItem {
  /** @format uuid */
  uuid?: string;
  /** @maxLength 256 */
  name: string;
  /** @pattern ^[-a-zA-Z0-9_]+$ */
  slug: string;
  product_ids: string[];
}

export interface ProductListOutputItem {
  /** @format uuid */
  uuid: string;
  latin_name: string | null;
  name: string;
  global_gap_product_id: number;
  planet_proof_product_id: string | null;
  planet_proof_name: string | null;
  leaf_name: string | null;
  leaf_product_id: string | null;
  /** @format uuid */
  product_group: string | null;
}

export enum RequestStatusEnum {
  Pending = 'pending',
  Approved = 'approved',
  Rejected = 'rejected',
  InspectionCreated = 'inspection_created',
}

export interface ResponseCreateInput {
  /** @format uuid */
  assessment_id: string;
  /** @format uuid */
  control_point_id: string;
  justification_text?: string;
  additional_detail_text?: string;
}

export interface ResponseCreateOutput {
  /** @format uuid */
  uuid?: string;
  /** @format uuid */
  assessment_id: string;
  /** @format uuid */
  control_point_id: string;
  /** @format uuid */
  cultivation_id: string | null;
  /** @format double */
  points?: number | null;
  /** @maxLength 2048 */
  justification_text?: string | null;
  /** @maxLength 2048 */
  additional_detail_text?: string | null;
  /** @format uuid */
  response_set_by_trigger_id: string | null;
  compliance_status?: ComplianceStatusEnum | null;
  is_response_set_by_evidence?: boolean;
  has_associated_visibility_triggers: boolean;
}

export interface ResponseDetailOutput {
  /** @format uuid */
  uuid?: string;
  /** @format uuid */
  assessment_id: string;
  /** @format uuid */
  control_point_id: string;
  /** @format uuid */
  cultivation_id: string | null;
  /** @format double */
  points?: number | null;
  /** @maxLength 2048 */
  justification_text?: string | null;
  /** @maxLength 2048 */
  additional_detail_text?: string | null;
  /** @format uuid */
  response_set_by_trigger_id: string | null;
  trigger_data?: ResponseDetailTriggerDataOutput;
  compliance_status?: ComplianceStatusEnum | null;
  compliance_status_label: string;
  is_response_set_by_evidence?: boolean;
}

export interface ResponseListOutputItem {
  /** @format uuid */
  uuid: string;
  /** @format uuid */
  assessment_id: string;
  /** @format uuid */
  control_point_id: string;
  /** @format uuid */
  cultivation_id: string | null;
  /** @format double */
  points: number | null;
  justification_text: string | null;
  additional_detail_text: string | null;
  /** @format uuid */
  response_set_by_trigger_id: string | null;
  trigger_data?: ResponseListTriggerDataOutput;
  compliance_status: ComplianceStatusEnum | null;
  compliance_status_label: string;
  is_response_set_by_evidence: boolean;
}

export interface ResponseUpdateInput {
  justification_text?: string;
  additional_detail_text?: string;
}

export interface ResponseUpdateOutput {
  /** @format uuid */
  uuid?: string;
  /** @format uuid */
  assessment_id: string;
  /** @format uuid */
  control_point_id: string;
  /** @format uuid */
  cultivation_id: string | null;
  /** @format double */
  points?: number | null;
  /** @maxLength 2048 */
  justification_text?: string | null;
  /** @maxLength 2048 */
  additional_detail_text?: string | null;
  /** @format uuid */
  response_set_by_trigger_id: string | null;
  trigger_data?: ResponseUpdateTriggerDataOutput;
  compliance_status?: ComplianceStatusEnum | null;
  is_response_set_by_evidence?: boolean;
  has_associated_visibility_triggers: boolean;
}

export interface RetailerListOutputItem {
  /** @format uuid */
  uuid: string;
  name: string;
  logo: SimpleFile | null;
}

export interface RoleListOutputItem {
  slug: string;
  name: string;
}

export enum RolesWithoutOwnerEnum {
  FarmEmployee = 'farm_employee',
  FarmManager = 'farm_manager',
  Auditor = 'auditor',
  AuditorReviewer = 'auditor_reviewer',
  CertificationBodyManager = 'certification_body_manager',
  StandardSetter = 'standard_setter',
  FarmAdvisor = 'farm_advisor',
  InternalAuditor = 'internal_auditor',
  InternalInspector = 'internal_inspector',
}

export enum SelectionStatusEnum {
  Included = 'included',
  NotApplicableProduct = 'not_applicable_product',
  ExcludedByMalusPoints = 'excluded_by_malus_points',
  ExcludedByUser = 'excluded_by_user',
}

export interface ShortcomingCorrectiveActionCreateInput {
  /** @format uuid */
  assessment_id: string;
  /** @format uuid */
  control_point_id: string;
  /** @format uuid */
  shortcoming_id: string;
  text: string;
  assessment_control_point_evidence_ids?: string[];
  /** @format date-time */
  reported_date: string;
}

export interface ShortcomingCorrectiveActionCreateOutput {
  /** @format uuid */
  uuid?: string;
  /** @format uuid */
  shortcoming_id: string;
  /** @format uuid */
  assessment_id: string;
  /** @format uuid */
  control_point_id: string;
  /** @maxLength 1024 */
  text?: string | null;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /** @format uuid */
  author_id: string | null;
  assessment_control_point_evidence_ids: string[];
  /** @format date-time */
  reported_date: string;
}

export interface ShortcomingCorrectiveActionDetailOutput {
  /** @format uuid */
  uuid?: string;
  /** @format uuid */
  shortcoming_id: string;
  /** @format uuid */
  assessment_id: string;
  /** @format uuid */
  control_point_id: string;
  /** @maxLength 1024 */
  text?: string | null;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /** @format uuid */
  author_id: string | null;
  assessment_control_point_evidence_ids: string[];
  /** @format date-time */
  reported_date: string;
}

export interface ShortcomingCorrectiveActionListOutputItem {
  /** @format uuid */
  uuid?: string;
  /** @format uuid */
  shortcoming_id: string;
  /** @format uuid */
  assessment_id: string;
  /** @format uuid */
  control_point_id: string;
  /** @maxLength 1024 */
  text?: string | null;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /** @format uuid */
  author_id: string | null;
  /** @format date-time */
  reported_date: string;
}

export interface ShortcomingCorrectiveActionUpdateInput {
  text?: string;
  assessment_control_point_evidence_ids?: string[];
  /** @format date-time */
  reported_date?: string;
}

export interface ShortcomingCorrectiveActionUpdateOutput {
  /** @format uuid */
  uuid?: string;
  /** @maxLength 1024 */
  text?: string | null;
  /** @format uuid */
  shortcoming_id: string;
  /** @format uuid */
  assessment_id: string;
  /** @format uuid */
  control_point_id: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  /** @format uuid */
  author_id: string | null;
  assessment_control_point_evidence_ids: string[];
  /** @format date-time */
  reported_date: string;
}

export interface SimpleFile {
  /** @format uuid */
  uuid: string;
  name: string;
  /** @format uri */
  file_object: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
}

export interface SmkThemeDetailOutput {
  /** @format uuid */
  uuid?: string;
  /** @maxLength 512 */
  name: string;
  /** @pattern ^[-a-zA-Z0-9_]+$ */
  slug: string;
  group?: GroupEnum | null;
}

export interface SmkThemeListOutputItem {
  /** @format uuid */
  uuid?: string;
  /** @maxLength 512 */
  name: string;
  /** @pattern ^[-a-zA-Z0-9_]+$ */
  slug: string;
  group?: GroupEnum | null;
}

export interface StandardDetailOutput {
  /** @format uuid */
  uuid?: string;
  /** @maxLength 512 */
  name: string;
  /** @maxLength 64 */
  version: string;
  module_ids: string[];
  uses_malus_points?: boolean;
  /** @format uuid */
  unique_identifier_id: string | null;
}

export interface StandardListOutputItem {
  /** @format uuid */
  uuid?: string;
  /** @maxLength 512 */
  name: string;
  /** @maxLength 64 */
  version: string;
  uses_malus_points?: boolean;
  /** @format uuid */
  unique_identifier_id: string | null;
  standard_setter: string;
}

export interface StandardLogoListOutputItem {
  /** @format uuid */
  standard_id: string;
  small_logo: SimpleFile | null;
}

export interface StandardSetterAssessmentListOutputItem {
  /** @format uuid */
  uuid: string;
  name: string;
  certification_body_name: string;
  client_identifier: string;
  /** @format date */
  audit_date: string;
  /** @format date */
  signed_date: string;
  status: string;
  status_label: string;
  /** @format date-time */
  created_at: string;
  metadata?: SsAssessmentListCbAssessmentMetadataOutput;
}

export interface StandardSetterEvidenceListOutputItem {
  /** @format uuid */
  uuid: string;
  name: string;
  farm_organization_name: string | null;
  certification_body_name: string;
  client_identifier: string;
  /** @format date */
  audit_date: string;
  /** @format date-time */
  created_at: string;
  /** @format uuid */
  evidence_type_id: string;
  /** @format uuid */
  source_help_document_id: string;
  file: SimpleFile | null;
}

export interface SubscriptionCreateInput {
  /** @format uuid */
  subscription_plan_id: string;
}

export interface SubscriptionCreateOutput {
  /** @format uuid */
  uuid?: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  subscription_plan_name: string;
  /** @format uuid */
  subscription_plan_id: string;
  /** @format date-time */
  payment_date?: string | null;
  /** @format date-time */
  start_date: string;
  /** @format date-time */
  end_date: string;
  subscription_status: string;
}

export interface SubscriptionDetailOutput {
  /** @format uuid */
  uuid?: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  modified_at: string;
  subscription_plan_name: string;
  /** @format uuid */
  subscription_plan_id: string;
  /** @format date-time */
  payment_date?: string | null;
  /** @format date-time */
  start_date: string;
  /** @format date-time */
  end_date: string;
  subscription_status: string;
}

export interface SubscriptionListOutputItem {
  /** @format uuid */
  uuid?: string;
  subscription_plan_name: string;
  /** @format uuid */
  subscription_plan_id: string;
  /** @format date-time */
  end_date: string;
  subscription_status: string;
  subscription_plan_standard_ids: string[];
}

export interface SubscriptionPlanDetailOutput {
  /** @format uuid */
  uuid?: string;
  /** @maxLength 128 */
  name: string;
  /** @pattern ^[-a-zA-Z0-9_]+$ */
  slug: string;
  description?: string | null;
  /**
   * @min 0
   * @max 2147483647
   */
  period?: number;
  /**
   * @format decimal
   * @pattern ^-?\d{0,6}(?:\.\d{0,2})?$
   */
  price: string;
  is_payment_required?: boolean;
  standard_ids: string[];
}

export interface SubscriptionPlanListOutputItem {
  /** @format uuid */
  uuid?: string;
  /** @maxLength 128 */
  name: string;
  /** @pattern ^[-a-zA-Z0-9_]+$ */
  slug: string;
  description?: string | null;
  /**
   * @min 0
   * @max 2147483647
   */
  period?: number;
  /**
   * @format decimal
   * @pattern ^-?\d{0,6}(?:\.\d{0,2})?$
   */
  price: string;
  is_payment_required?: boolean;
}

export enum TaxonomicLevelEnum {
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
}

export interface UserProfileCreateOutput {
  /** @format uuid */
  uuid?: string;
  /** @maxLength 256 */
  full_name: string;
  /**
   * @format email
   * @maxLength 256
   */
  email: string;
  /** @maxLength 3 */
  language?: string;
  memberships?: MembershipsCreate[];
  account_type?: AccountTypeEnum;
}

export interface UserProfileDetailOutput {
  /** @format uuid */
  uuid?: string;
  /** @maxLength 256 */
  full_name: string;
  /**
   * @format email
   * @maxLength 256
   */
  email: string;
  /** @maxLength 3 */
  language?: string;
  memberships?: MembershipsDetail[];
  account_type?: AccountTypeEnum;
  origin?: OriginEnum;
}

export interface UserProfileUpdateInput {
  full_name?: string;
  language?: string;
}

export interface UserProfileUpdateOutput {
  /** @format uuid */
  uuid?: string;
  /** @maxLength 256 */
  full_name: string;
  /**
   * @format email
   * @maxLength 256
   */
  email: string;
  /** @maxLength 3 */
  language?: string;
  memberships?: MembershipsUpdate[];
  account_type?: AccountTypeEnum;
}

export interface V2AssessmentControlPointDetailOutput {
  /** @format uuid */
  uuid: string;
  /** @format uuid */
  control_point_id: string;
  comments: AssessmentControlPointCommentOutputItem[];
  evidence: AssessmentControlPointEvidenceOutputItem[];
  shortcomings: AssessmentControlPointShortcomingOutputItem[];
  questions: AssessmentControlPointQuestionOutputItem[];
  responses: AssessmentControlPointResponseOutputItem[];
  retailer_ids: string[];
  can_attach_evidence: boolean;
}

export interface V2AssessmentControlPointListOutputItem {
  /** @format uuid */
  uuid: string;
  /** @format uuid */
  control_point_id: string;
  /** @format uuid */
  criticality_id: string | null;
  /** @maxLength 2048 */
  main_text: string;
  /** @maxLength 128 */
  code: string;
  /** @maxLength 128 */
  display_code?: string | null;
  level?: LevelEnum;
  allows_bulk_cultivation_answers?: boolean;
  is_evidence_shared_with_standard_setter?: boolean;
  show_control_point_level?: boolean;
  standard_setter?: string;
  /** @format uuid */
  module_id: string;
  is_flagged: boolean;
  is_scored?: boolean;
  categories?: Categories[];
  /** @format uuid */
  agriplace_criticality_id: string;
  evidence_type_ids: string[];
  /** @format uuid */
  level_3_module_id: string;
  can_attach_evidence?: boolean;
  is_multiple_response: boolean;
  additional_response_label: string;
}

export interface ValidEvidenceExistsOutput {
  valid_evidence_exists: boolean;
}

export interface ActiveSubscriptionStandardsOrganizationDetail {
  uuid: string;
  name: string;
}

export interface AssessmentCategoryStatusOutput {
  /** @format uuid */
  uuid: string;
  status: CategoryEvidenceTypeStatusEnum;
}

export interface AssessmentConfigBulkUpdateInput {
  /** @format uuid */
  standard_id: string;
  /** @format uuid */
  organization_cultivation_id?: string | null;
  malus_points?: number | null;
  /** @default "included" */
  selection_status?: SelectionStatusEnum;
}

export interface AssessmentControlPointControlPointInputList {
  /** @format uuid */
  uuid: string;
  name: string;
  is_editable: boolean;
  /** @format uuid */
  possible_answer_set_id: string;
  is_answer: boolean;
  index: number;
  answer_type: string;
  /** @format double */
  max_answer_value: number;
  /** @format double */
  min_answer_value: number;
  max_decimal_places: number;
}

export interface AssessmentControlPointControlPointQuestionList {
  /** @format uuid */
  uuid: string;
  label: string;
  /** @format uuid */
  possible_answer_set_id: string;
  position: number;
  is_editable: boolean;
  is_multiple_choice: boolean;
  /** @format double */
  max_answer_value: number;
  /** @format double */
  min_answer_value: number;
  max_decimal_places: number;
}

export interface AssessmentControlPointInputControlPointInputList {
  /** @format uuid */
  uuid: string;
  name: string;
  is_editable: boolean;
  /** @format uuid */
  possible_answer_set_id: string;
  is_answer: boolean;
  index: number;
  answer_type: string;
  /** @format double */
  max_answer_value: number;
  /** @format double */
  min_answer_value: number;
  max_decimal_places: number;
}

export interface AssessmentControlPointInputControlPointInputUpdate {
  /** @format uuid */
  uuid: string;
  name: string;
  is_editable: boolean;
  /** @format uuid */
  possible_answer_set_id: string;
  is_answer: boolean;
  index: number;
}

export interface AssessmentCreateCbAssessmentMetadataInput {
  farm_name: string;
  farm_address: string;
  farm_country: CountryEnum;
  farm_city: string;
  farm_postal_code: string;
}

export interface AssessmentDetailCbAssessmentMetadataOutput {
  /** @format uuid */
  uuid: string;
  farm_name: string;
  farm_address: string;
  farm_country: CountryEnum;
  farm_city: string;
  farm_postal_code: string;
}

export interface AssessmentEvidenceTypeStatusOutput {
  /** @format uuid */
  uuid: string;
  status: CategoryEvidenceTypeStatusEnum;
}

export interface AssessmentListCbAssessmentMetadataOutput {
  /** @format uuid */
  uuid: string;
  farm_name: string;
  farm_address: string;
  farm_country: CountryEnum;
  farm_city: string;
  farm_postal_code: string;
}

export interface AssessmentPlotCultivationCreateClientIdentifier {
  value: string;
  uuid: string;
}

export interface AssessmentPlotCultivationCreateOrganizationPlot {
  uuid: string;
  name: string;
  /** @format double */
  area: number;
}

export interface AssessmentPlotCultivationCreateProduct {
  uuid: string;
  latin_name: string;
  name: string;
}

export interface AssessmentPlotCultivationCreateProductionTechnique {
  uuid: string;
  name: string;
}

export interface AssessmentPlotCultivationDetailClientIdentifier {
  value: string;
  uuid: string;
}

export interface AssessmentPlotCultivationDetailOrganizationPlot {
  uuid: string;
  name: string;
  /** @format double */
  area: number;
}

export interface AssessmentPlotCultivationDetailProduct {
  uuid: string;
  latin_name: string;
  name: string;
}

export interface AssessmentPlotCultivationDetailProductionTechnique {
  uuid: string;
  name: string;
}

export interface AssessmentPlotCultivationListClientIdentifier {
  value: string;
  uuid: string;
}

export interface AssessmentPlotCultivationListProduct {
  uuid: string;
  latin_name: string;
  name: string;
}

export interface AssessmentPlotCultivationListProductionTechnique {
  uuid: string;
  name: string;
}

export interface AssessmentPlotCultivationUpdateClientIdentifier {
  value: string;
  uuid: string;
}

export interface AssessmentPlotCultivationUpdateProduct {
  uuid: string;
  latin_name: string;
  name: string;
}

export interface AssessmentPlotCultivationUpdateProductionTechnique {
  uuid: string;
  name: string;
}

export interface BioSoilCropThemeBonusPoints {
  /** @format double */
  label: number;
  status: string;
}

export interface CamundaWorkflowsOrganizationCreate {
  uuid: string;
  name: string;
}

export interface CamundaWorkflowsOrganizationDetail {
  uuid: string;
  name: string;
}

export interface Categories {
  /** @format uuid */
  uuid: string;
  name: string;
  taxonomic_level: number;
}

export interface ClientIdentifierOrganizationCultivationListOutputItem {
  value: string;
  uuid: string;
}

export interface ClientIdentifierOrganizationEvidenceCreateOutputItem {
  value: string;
  uuid: string;
}

export interface ClientIdentifierOrganizationEvidenceDetailOutputItem {
  value: string;
  uuid: string;
}

export interface ClientIdentifierOrganizationEvidenceListOutputItem {
  value: string;
  uuid: string;
}

export interface ClientIdentifierOrganizationEvidenceUpdateOutputItem {
  value: string;
  uuid: string;
}

export interface CompletionArgumentsCamundaTaskComplete {
  name: string;
  type: string;
}

export interface CompletionArgumentsCamundaTaskDetail {
  name: string;
  type: string;
}

export interface ComplianceIndication {
  label: string;
  status: string;
}

export interface CriticalityData {
  /** @format uuid */
  criticality_id: string;
  criticality_name: string;
  criticality_slug: string;
  criticality_hierarchy: number;
  text: string;
  label: number;
  status: string;
}

export interface CultivationThemes {
  /** @format uuid */
  theme_id: string;
  theme_name: string;
  theme_slug: string;
  /** @format double */
  bonus_points: number;
  /** @format double */
  minimum_bonus_points_required: number;
  label: string;
  status: string;
}

export interface CurrentBalance {
  /** @format double */
  label: number;
  status: string;
}

export interface DraftDetachedEvidence {
  name: string;
  /** @format uuid */
  control_point: string;
}

export interface HelpDocumentFileDetail {
  /** @format uuid */
  uuid: string;
  name: string;
  /** @format uri */
  file_object: string;
  version: string;
  /** @format uuid */
  revision_of_id: string;
}

export interface HelpDocumentFileList {
  /** @format uuid */
  uuid: string;
  name: string;
  /** @format uri */
  file_object: string;
  version: string;
  /** @format uuid */
  revision_of_id: string;
}

export interface MembershipsCreate {
  tenant_id: string;
  organization_id: string;
  organization_name: string;
  roles: string[];
}

export interface MembershipsDetail {
  tenant_id: string;
  organization_id: string;
  organization_name: string;
  roles: string[];
}

export interface MembershipsUpdate {
  tenant_id: string;
  organization_id: string;
  organization_name: string;
  roles: string[];
}

export interface ModuleCultivationSelections {
  /** @format uuid */
  module_id: string;
  is_module_visible: boolean;
  /** @format uuid */
  organization_cultivation_id: string;
  malus_points: number;
}

export interface ModuleCultivationSelectionsUpdateInput {
  /** @format uuid */
  module_id: string;
  /** @format uuid */
  organization_cultivation_id?: string;
  malus_points?: number;
}

export interface OrganizationClientIdentifierCreateCertificationBodyOutput {
  uuid: string;
  name: string;
}

export interface OrganizationClientIdentifierCreateStandardOutput {
  uuid: string;
  name: string;
}

export interface OrganizationClientIdentifierDetailCertificationBodyOutput {
  uuid: string;
  name: string;
}

export interface OrganizationClientIdentifierDetailStandardOutput {
  uuid: string;
  name: string;
}

export interface OrganizationClientIdentifierListCertificationBodyOutput {
  uuid: string;
  name: string;
}

export interface OrganizationClientIdentifierListStandardOutput {
  uuid: string;
  name: string;
}

export interface OrganizationClientIdentifierMoveCertificationBodyOutput {
  uuid: string;
  name: string;
}

export interface OrganizationClientIdentifierMoveStandardStandardOutputItem {
  uuid: string;
  name: string;
}

export interface OrganizationClientIdentifierUpdateCertificationBodyOutput {
  uuid: string;
  name: string;
}

export interface OrganizationClientIdentifierUpdateStandardOutput {
  uuid: string;
  name: string;
}

export interface OrganizationClientIdentifiersOrganizationCreateOutputItem {
  /** @format uuid */
  uuid: string;
  value: string;
}

export interface OrganizationClientIdentifiersOrganizationDetailOutputItem {
  /** @format uuid */
  uuid: string;
  value: string;
}

export interface OrganizationClientIdentifiersOrganizationUpdateOutputItem {
  /** @format uuid */
  uuid: string;
  value: string;
}

export interface OrganizationCultivationsAssessmentControlPointCultivationList {
  /** @format uuid */
  organization_cultivation_id: string;
  name: string;
  /** @format uuid */
  product_id: string;
  product_name: string;
  /** @format uuid */
  production_technique_id: string;
  production_technique_name: string;
}

export interface PlotCultivationCreateOrganizationPlot {
  uuid: string;
  name: string;
}

export interface PlotCultivationCreateProduct {
  uuid: string;
  latin_name: string;
  name: string;
}

export interface PlotCultivationCreateProductionTechnique {
  uuid: string;
  name: string;
}

export interface PlotCultivationDetailOrganizationPlot {
  uuid: string;
  name: string;
  /** @format double */
  area: number;
}

export interface PlotCultivationDetailProduct {
  uuid: string;
  latin_name: string;
  name: string;
  planet_proof_name: string;
  leaf_name: string;
  /** @format uuid */
  product_group_id: string;
}

export interface PlotCultivationDetailProductionTechnique {
  uuid: string;
  name: string;
}

export interface PlotCultivationListProduct {
  uuid: string;
  latin_name: string;
  name: string;
  planet_proof_name: string;
  leaf_name: string;
  /** @format uuid */
  product_group_id: string;
  product_group_name: string;
}

export interface PlotCultivationListProductionTechnique {
  uuid: string;
  name: string;
}

export interface PlotCultivationUpdateProduct {
  uuid: string;
  latin_name: string;
  name: string;
}

export interface PlotCultivationUpdateProductionTechnique {
  uuid: string;
  name: string;
}

export interface PreliminaryQuestionAnswerListBulkUpdateDraftOutput {
  detached_evidence: DraftDetachedEvidence[];
}

export interface PreliminaryQuestionAnswerListBulkUpdateInput {
  /** @format uuid */
  possible_answer_id?: string;
  /** @format uuid */
  preliminary_question_id?: string;
  dynamic_answer?: string | number;
}

export interface PreliminaryQuestionAnswerListBulkUpdateOutput {
  /** @format uuid */
  uuid: string;
  /** @format uuid */
  possible_answer_id: string;
  /** @format uuid */
  preliminary_question_id: string;
  dynamic_answer: string | number;
}

export interface ProcessInstanceVariablesCamundaTaskComplete {
  /** @format uuid */
  assessment_id: string;
}

export interface ProcessInstanceVariablesCamundaTaskDetail {
  /** @format uuid */
  assessment_id: string;
}

export interface Product {
  uuid: string;
  latin_name: string;
  name: string;
}

export interface ProductionTechniqueOrganizationCultivationList {
  uuid: string;
  name: string;
}

export interface ResponseDetailTriggerDataOutput {
  trigger_type: string;
  trigger_name: string;
  trigger_answer: string;
}

export interface ResponseListTriggerDataOutput {
  trigger_type: string;
  trigger_name: string;
  trigger_answer: string;
}

export interface ResponseUpdateTriggerDataOutput {
  trigger_type: string;
  trigger_name: string;
  trigger_answer: string;
}

export interface SsAssessmentListCbAssessmentMetadataOutput {
  /** @format uuid */
  uuid: string;
  farm_name: string;
  farm_address: string;
  farm_country: CountryEnum;
  farm_city: string;
  farm_postal_code: string;
}

export interface TaskVariablesCamundaTaskComplete {
  available_new_task_slugs: any[];
  completion_arguments?: CompletionArgumentsCamundaTaskComplete[];
}

export interface TaskVariablesCamundaTaskDetail {
  available_new_task_slugs: any[];
  completion_arguments?: CompletionArgumentsCamundaTaskDetail[];
}

export interface UniqueIdentifierOrganizationUniqueIdentifierCreate {
  uuid: string;
  name: string;
  slug: string;
}

export interface UniqueIdentifierOrganizationUniqueIdentifierDetail {
  uuid: string;
  name: string;
  slug: string;
}

export interface UniqueIdentifierOrganizationUniqueIdentifierList {
  uuid: string;
  name: string;
  slug: string;
}

export interface UniqueIdentifierOrganizationUniqueIdentifierUpdate {
  uuid: string;
  name: string;
  slug: string;
}

export enum RolesEnum {
  Owner = 'owner',
  FarmEmployee = 'farm_employee',
  FarmManager = 'farm_manager',
  Auditor = 'auditor',
  AuditorReviewer = 'auditor_reviewer',
  CertificationBodyManager = 'certification_body_manager',
  StandardSetter = 'standard_setter',
  FarmAdvisor = 'farm_advisor',
  InternalAuditor = 'internal_auditor',
  InternalInspector = 'internal_inspector',
}

export enum OrganizationTypesEnum {
  Farm = 'farm',
  CertificationBody = 'certification-body',
  StandardSetter = 'standard-setter',
}

export enum TopCountryEnum {
  NL = 'NL',
  BE = 'BE',
  DE = 'DE',
  ES = 'ES',
  FR = 'FR',
  IT = 'IT',
}

export interface CategoriesListParams {
  parent_category_id?: string[];
  taxonomic_level?: (1 | 2 | 3)[];
}

export interface ControlPointCriticalitiesListParams {
  assessment_id?: string;
}

export interface ControlPointInputsListParams {
  control_point_id?: string[];
}

export interface ControlPointsListParams {
  module_id?: string[];
}

export interface HelpDocumentsListParams {
  category_and_evidence_type_based_control_point_id?: string[];
  category_id?: string[];
  control_point_id?: string[];
  evidence_type_id?: string[];
  help_document_type_id?: string[];
  is_evidence_form?: boolean;
  organization_id?: string[];
}

export interface ModulesListParams {
  get_family_by_module_id?: string[];
  has_parent?: boolean;
  standard_id?: string[];
}

export interface OrganizationInvitationsInviteeInvitationsListParams {
  is_pending?: boolean;
}

export interface PreliminaryQuestionsListParams {
  /** @format uuid */
  assessment_id?: string;
  /** @format uuid */
  preliminary_question_set_id?: string;
  version?: string;
}

export interface ProductsListParams {
  module_id?: string[];
  /** @format uuid */
  product_group_id?: string | null;
  standard_id?: string[];
}

export interface AssessmentConfigListParams {
  /** @format uuid */
  assessment_id?: string;
  /** @format uuid */
  standard_id?: string;
  tid: string;
}

export interface AssessmentControlPointAnswersListParams {
  assessment_id?: string[];
  control_point_id?: string[];
  tid: string;
}

export interface AssessmentControlPointCommentsListParams {
  assessment_id?: string[];
  control_point_id?: string[];
  tid: string;
}

export interface AssessmentControlPointEvidenceListParams {
  assessment_id?: string[];
  control_point_id?: string[];
  tid: string;
}

export interface AssessmentControlPointInputsListParams {
  assessment_id?: string[];
  control_point_id?: string[];
  tid: string;
}

export interface AssessmentControlPointShortcomingsListParams {
  assessment_id?: string[];
  control_point_id?: string[];
  tid: string;
}

export interface AssessmentControlPointsListParams {
  answer_type?: ('full' | 'none' | 'partial')[];
  /** @format uuid */
  assessment_id?: string;
  category_id?: string[];
  criticality_id?: string[];
  evidence_type_id?: string[];
  exclude_na?: boolean;
  has_comments?: boolean;
  has_evidence?: boolean;
  has_shortcomings?: boolean;
  is_flagged?: boolean;
  module_id?: string[];
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  page_size?: number;
  verification_method_id?: string[];
  tid: string;
}

export interface AssessmentControlPointsGetCategoryEvidenceTypeControlPointsListParams {
  agriplace_criticality_id?: string[];
  /** @format uuid */
  assessment_id: string;
  category_id?: string;
  /** @format uuid */
  evidence_type_id?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  page_size?: number;
  tid: string;
}

export interface AssessmentExportsJsonExportCreateParams {
  assessment_id: string[];
  tid: string;
}

export interface AssessmentPlotCultivationsListParams {
  audit_year?: number;
  client_identifier_id?: string[];
  assessmentId: string;
  tid: string;
}

export interface AssessmentPreliminaryQuestionAnswersListParams {
  assessment_id?: string[];
  tid: string;
}

export interface AssessmentProgressGetAssessmentProgressListParams {
  module_id: string;
  tid: string;
  /**
   * A UUID string identifying this module.
   * @format uuid
   */
  uuid: string;
}

export interface AssessmentStandardRequestsListParams {
  /** @format uuid */
  requester_user_profile_id?: string;
  status?: ('approved' | 'inspection_created' | 'pending' | 'rejected')[];
  tid: string;
}

export interface AssessmentsListParams {
  /** @format uuid */
  author_id?: string;
  standard_id?: string[];
  status?: (
    | 'closed'
    | 'confirmed'
    | 'declined'
    | 'draft'
    | 'open'
    | 'submitted'
    | 'to_be_audited'
    | 'to_be_confirmed'
    | 'to_be_reviewed'
  )[];
  tid: string;
}

export interface AssessmentsRetrieveParams {
  include_child_modules?: boolean;
  tid: string;
  /**
   * A UUID string identifying this assessment.
   * @format uuid
   */
  uuid: string;
}

export interface AssessmentsGetAssessmentControlPointCultivationsListParams {
  include_only_non_compliant_cultivations?: boolean;
  tid: string;
  /**
   * A UUID string identifying this assessment control point.
   * @format uuid
   */
  uuid: string;
}

export interface AssessmentsGetCategoryEvidenceTypeStatusRetrieveParams {
  /** @format uuid */
  agriplace_criticality_id: string;
  category_id: string;
  id: string;
  tid: string;
}

export interface AssessmentsGetCategoryEvidenceTypesListParams {
  has_evidence_types?: boolean;
  id: string;
  tid: string;
}

export interface AssessmentsGetCriticalityCategoryEvidenceTypesListParams {
  /** @format uuid */
  agriplace_criticality_id?: string;
  id: string;
  tid: string;
}

export interface AssessmentsGetModuleSidebarListParams {
  min_level?: '1';
  id: string;
  tid: string;
}

export interface CamundaProcessInstancesListParams {
  /** @format uuid */
  assessment_id?: string;
  /** @format uuid */
  process_definition_id?: string;
  /** @minLength 1 */
  process_definition_key?: string;
  /** @minLength 1 */
  process_definition_name?: string;
  process_instance_ids?: string[];
  tid: string;
}

export interface CamundaProcessInstancesByAssessmentIdRetrieveParams {
  /** @minLength 1 */
  process_definition_id?: string;
  tid: string;
  /**
   * A UUID string identifying this assessment.
   * @format uuid
   */
  uuid: string;
}

export interface CamundaTasksListParams {
  /** @format uuid */
  process_instance_id?: string;
  tid: string;
}

export interface CbAssessmentMetadataListParams {
  /** @format uuid */
  assessment_id?: string;
  tid: string;
}

export interface EvidenceListParams {
  auditor_assessment_id?: string;
  client_identifier_id?: string[];
  control_point_id?: string;
  is_archived?: boolean;
  is_expired?: boolean;
  tid: string;
}

export interface EvidenceRetrieveParams {
  auditor_assessment_id?: string;
  tid: string;
  /**
   * A UUID string identifying this evidence.
   * @format uuid
   */
  uuid: string;
}

export interface OrganizationCultivationsListParams {
  audit_year?: number;
  client_identifier_id?: string[];
  tid: string;
}

export interface OrganizationInvitationsInviterInvitationsListParams {
  is_expired?: boolean;
  is_pending?: boolean;
  tid: string;
}

export interface OrganizationTypeRolesListParams {
  include_owner?: boolean;
  tid: string;
}

export interface ResponsesListParams {
  assessment_id?: string[];
  control_point_id?: string[];
  tid: string;
}

export interface ShortcomingCorrectiveActionsListParams {
  shortcoming_id?: string[];
  tid: string;
}

export interface StandardSetterAssessmentsListParams {
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  page_size?: number;
  tid: string;
}

export interface StandardSetterEvidenceListParams {
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  page_size?: number;
  tid: string;
}

export interface SubscriptionsListParams {
  status?: ('active' | 'inactive' | 'pending_payment')[];
  tid: string;
}

export interface V2AssessmentControlPointsListParams {
  answer_type?: ('full' | 'none' | 'partial')[];
  /** @format uuid */
  assessment_id?: string;
  category_id?: string[];
  criticality_id?: string[];
  evidence_type_id?: string[];
  exclude_na?: boolean;
  has_comments?: boolean;
  has_evidence?: boolean;
  has_shortcomings?: boolean;
  is_flagged?: boolean;
  module_id?: string[];
  /** A page number within the paginated result set. */
  page?: number;
  /** Number of results to return per page. */
  page_size?: number;
  verification_method_id?: string[];
  tid: string;
}
