import {
  type AssessmentOrganizationPlotCultivationCreateInput,
  type AssessmentOrganizationPlotCultivationCreateOutput,
  type AssessmentOrganizationPlotCultivationDetailOutput,
  type AssessmentOrganizationPlotCultivationListOutputItem,
  type AssessmentOrganizationPlotCultivationUpdateInput,
  type AssessmentOrganizationPlotCultivationUpdateOutput,
  type AssessmentPlotCultivationsListParams,
} from 'src/__generated__/InternalApiTypes';

import { baseApi } from '../baseApi';
import {
  type CreateParamsWithTenantId,
  type DeleteParamsWithTenantId,
  type DetailParamsWithTenantId,
  type UpdateParamsWithTenantId,
} from '../helpers';
import { TagType } from '../TagType';

const RESOURCE = 'assessment-plot-cultivations';

interface DetailParamsWithAssessmentId extends DetailParamsWithTenantId {
  assessmentId: string;
}

interface DeleteParamsWithAssessmentId extends DeleteParamsWithTenantId {
  assessmentId: string;
}

interface CreateParamsWithAssessmentId
  extends CreateParamsWithTenantId<AssessmentOrganizationPlotCultivationCreateInput> {
  assessmentId: string;
}

interface UpdateParamsWithAssessmentId
  extends UpdateParamsWithTenantId<AssessmentOrganizationPlotCultivationUpdateInput> {
  assessmentId: string;
}

const assessmentPlotProductEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAssessmentPlotProductList: build.query<
      AssessmentOrganizationPlotCultivationListOutputItem[],
      AssessmentPlotCultivationsListParams
    >({
      query: ({ tid, assessmentId }) => `/tid/${tid}/${RESOURCE}/${assessmentId}/`,
      providesTags: [TagType.AssessmentPlotProducts],
    }),

    getAssessmentPlotProduct: build.query<
      AssessmentOrganizationPlotCultivationDetailOutput,
      DetailParamsWithAssessmentId
    >({
      query: ({ assessmentId, id, tid }) => `/tid/${tid}/${RESOURCE}/${assessmentId}/${id}/`,
      providesTags: [TagType.AssessmentPlotProducts, TagType.UserProfile],
    }),

    createAssessmentPlotProduct: build.mutation<
      AssessmentOrganizationPlotCultivationCreateOutput,
      CreateParamsWithAssessmentId
    >({
      query: ({ assessmentId, body, tid }) => ({
        body,
        url: `/tid/${tid}/${RESOURCE}/${assessmentId}/create/`,
        method: 'POST',
      }),
      invalidatesTags: [TagType.AssessmentPlotProducts],
    }),

    deleteAssessmentPlotProduct: build.mutation<void, DeleteParamsWithAssessmentId>({
      query: ({ assessmentId, id, tid }) => ({
        url: `/tid/${tid}/${RESOURCE}/${assessmentId}/${id}/delete/`,
        method: 'DELETE',
      }),
      invalidatesTags: [TagType.AssessmentPlotProducts],
    }),

    updateAssessmentPlotProduct: build.mutation<
      AssessmentOrganizationPlotCultivationUpdateOutput,
      UpdateParamsWithAssessmentId
    >({
      query: ({ assessmentId, body, id, tid }) => ({
        body,
        url: `/tid/${tid}/${RESOURCE}/${assessmentId}/${id}/update/`,
        method: 'PATCH',
      }),
      invalidatesTags: [TagType.AssessmentPlotProducts],
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateAssessmentPlotProductMutation,
  useDeleteAssessmentPlotProductMutation,
  useGetAssessmentPlotProductListQuery,
  useGetAssessmentPlotProductQuery,
  useUpdateAssessmentPlotProductMutation,
} = assessmentPlotProductEndpoints;
