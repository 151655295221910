import { Box, Button, Link as MuiLink, type SxProps, Typography } from '@mui/material';
import LeafLogo from 'assets/leaf-logo.jpg';
import PlanetProofLogo from 'assets/planetproof-logo.svg';
import SMKLogo from 'assets/smk-logo.png';
import { useContext } from 'react';
import { ThemeContext } from 'src/components/ThemeProvider';
import { useGetUserOrigin } from 'src/hooks';
import { appLogoStrings } from 'src/languages/en-UK';
import { SignUpOriginEnum } from 'src/services/farmApi/endpoints/auth';
import { appColors, leafTheme, smkTheme } from 'src/theme';

import { Logo } from './Logo';

interface AppLogoProps {
  large?: boolean;
  sx?: SxProps;
  showName?: boolean;
  showSmallLogo?: boolean;
}

export const AppLogo = ({
  showSmallLogo = false,
  showName = false,
  large = false,
  sx = {},
}: AppLogoProps): React.JSX.Element => {
  const logoSize = large ? 72 : 36;
  const fontSize = large ? 32 : 16;
  const origin = useGetUserOrigin();
  const { theme: currentTheme } = useContext(ThemeContext);

  const renderSmkLogo = () =>
    showSmallLogo ? (
      <img
        alt="Planet Proof Logo"
        src={PlanetProofLogo}
        style={{ width: logoSize, height: logoSize }}
      />
    ) : (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <img
            alt="Planet Proof Logo"
            src={PlanetProofLogo}
            style={{ width: 114, height: 110 }}
          />
          <img
            alt="SMK Logo"
            src={SMKLogo}
            style={{
              width: '48.335vw',
              maxWidth: 217.5,
              marginLeft: 24,
            }}
          />
        </Box>
        <Box
          sx={{
            mb: 2.5,
            mt: 5,
            mx: { xs: 0, sm: 3 },
          }}
        >
          <Typography
            component="span"
            variant="body1"
          >
            {appLogoStrings('toApplyFor')}
            <Button
              color="info"
              component={MuiLink}
              href="https://www.planetproof.eu/en/certify/plant-products/"
              sx={{
                p: 0,
                fontSize: 16,
                fontWeight: 400,
                lineHeight: 1.5,
                mt: -0.5,
                textTransform: 'initial',
              }}
              variant="text"
            >
              <Typography variant="body1">On the way to PlanetProof</Typography>
            </Button>
            {appLogoStrings('createAccountPlanetProofMessage')}
          </Typography>
        </Box>
      </Box>
    );

  const renderLeafLogo = () => (
    <img
      alt="Leaf Logo"
      src={LeafLogo}
      style={{ width: logoSize, height: logoSize }}
    />
  );

  const renderAgriplaceLogo = () => (
    <>
      <Logo size={logoSize} />
      {showName && (
        <>
          <Typography
            noWrap
            sx={{
              color: appColors.chain,
              fontFamily: 'Poppins',
              fontSize: { xs: 24, sm: fontSize },
              fontWeight: 600,
              ml: 1,
              mt: '-2px',
            }}
          >
            agriplace
          </Typography>
          <Typography
            noWrap
            sx={{
              color: 'primary.main',
              fontFamily: 'Poppins',
              fontSize: { xs: 24, sm: fontSize },
              fontWeight: 300,
              ml: 1,
              mt: '-2px',
            }}
          >
            farm
          </Typography>
        </>
      )}
    </>
  );

  const renderLogo = () => {
    if (origin === SignUpOriginEnum.SMK || currentTheme === smkTheme) {
      return renderSmkLogo();
    }
    if (origin === SignUpOriginEnum.LEAF || currentTheme === leafTheme) {
      return renderLeafLogo();
    }
    return renderAgriplaceLogo();
  };

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        fontFamily: 'Poppins',
        justifyContent: 'center',
        ...sx,
      }}
    >
      {renderLogo()}
    </Box>
  );
};
