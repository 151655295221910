import { Navigate, useLocation } from 'react-router-dom';
import { AccountTypeEnum } from 'src/__generated__/InternalApiTypes';
import { AuthStatus } from 'src/enums/AuthStatus';
import { useGetCurrentUserProfile, useGetUserOrigin } from 'src/hooks';
import { useUserAuth } from 'src/hooks/useUserAuth';
import {
  ROUTE_PATH_ACCOUNT,
  ROUTE_PATH_CREATE_ORGANIZATION,
  ROUTE_PATH_DASHBOARD,
  ROUTE_PATH_LOG_IN,
} from 'src/settings';

import { LoadingArea } from '../common/LoadingArea';

interface RequireAuthProps {
  children: React.JSX.Element;
}

export const RequireAuth = ({ children }: RequireAuthProps): React.JSX.Element => {
  const location = useLocation();
  const { authStatus } = useUserAuth();
  // Parse redirect url from link
  const redirectPath = (window.location.pathname + window.location.search).substring(1);
  const { data: userProfile } = useGetCurrentUserProfile() ?? {};
  const hasRedirect = localStorage.getItem('hasRedirect');
  const origin = useGetUserOrigin();
  // If the user is not logged in, but route requires user to be...
  if (authStatus === AuthStatus.LoggedOut) {
    // ...save intended location to send users on after log in
    let navigateLink = ROUTE_PATH_LOG_IN;
    if (origin) {
      navigateLink = `${ROUTE_PATH_LOG_IN}?origin=${origin}`;
      if (redirectPath && !hasRedirect) {
        navigateLink = `${navigateLink}&redirect=${encodeURIComponent(redirectPath)}`;
      }
    } else if (redirectPath && !hasRedirect) {
      navigateLink = `${navigateLink}?redirect=${encodeURIComponent(redirectPath)}`;
    }

    return (
      <Navigate
        replace
        state={{ from: location }}
        to={navigateLink}
      />
    );
  }

  // If the user is logged in...
  if (authStatus === AuthStatus.LoggedIn) {
    // ...but the user profile is still missing, render a loading spinner
    if (!userProfile) {
      return (
        <LoadingArea
          hasAgriplaceLoadingAnimation
          size={72}
        />
      );
    }

    // If the user profile is there but has no organizations, always re-route to organization page
    if (
      location.pathname !== ROUTE_PATH_CREATE_ORGANIZATION &&
      location.pathname !== ROUTE_PATH_ACCOUNT &&
      location.pathname !== ROUTE_PATH_DASHBOARD &&
      userProfile.memberships &&
      userProfile.memberships.length < 1 &&
      userProfile.account_type !== AccountTypeEnum.Auditor
    ) {
      return (
        <Navigate
          replace
          to={ROUTE_PATH_CREATE_ORGANIZATION}
        />
      );
    }

    return children;
  }

  // If the user is neither logged in nor logged out, show a loading spinner
  return (
    <LoadingArea
      hasAgriplaceLoadingAnimation
      size={72}
    />
  );
};
