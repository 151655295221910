import { type ComponentType, lazy, type PropsWithoutRef, Suspense } from 'react';

import { LoadingArea } from '../common';

export const AsyncComponent = <P extends object>(
  importComponent: () => Promise<{ default: ComponentType<P> }>,
) => {
  const Component = lazy(importComponent);
  return function (props: PropsWithoutRef<P>) {
    return (
      <Suspense
        fallback={
          <LoadingArea
            hasAgriplaceLoadingAnimation
            size={72}
          />
        }
      >
        <Component {...props} />
      </Suspense>
    );
  };
};
