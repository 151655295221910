import {
  type OrganizationPlotCultivationCreateInput,
  type OrganizationPlotCultivationCreateOutput,
  type OrganizationPlotCultivationDetailOutput,
  type OrganizationPlotCultivationListOutputItem,
  type OrganizationPlotCultivationUpdateInput,
  type OrganizationPlotCultivationUpdateOutput,
} from 'src/__generated__/InternalApiTypes';

import { baseApi } from '../baseApi';
import {
  type CreateParamsWithTenantId,
  type DeleteParamsWithTenantId,
  type DetailParamsWithTenantId,
  type UpdateParamsWithTenantId,
} from '../helpers';
import { TagType } from '../TagType';

const RESOURCE = 'organization-plot-cultivations';

const organizationPlotProductEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getOrganizationPlotProductList: build.query<
      OrganizationPlotCultivationListOutputItem[],
      { tid: string }
    >({
      query: ({ tid }) => `/tid/${tid}/${RESOURCE}/`,
      providesTags: [TagType.OrganizationPlotProduct],
    }),

    getOrganizationPlotProduct: build.query<
      OrganizationPlotCultivationDetailOutput,
      DetailParamsWithTenantId
    >({
      query: ({ id, tid }) => `/tid/${tid}/${RESOURCE}/${id}/`,
      providesTags: [TagType.OrganizationPlotProduct, TagType.UserProfile],
    }),

    createOrganizationPlotProduct: build.mutation<
      OrganizationPlotCultivationCreateOutput,
      CreateParamsWithTenantId<OrganizationPlotCultivationCreateInput>
    >({
      query: ({ body, tid }) => ({
        body,
        url: `/tid/${tid}/${RESOURCE}/create/`,
        method: 'POST',
      }),
      invalidatesTags: [TagType.OrganizationPlotProduct],
    }),

    deleteOrganizationPlotProduct: build.mutation<void, DeleteParamsWithTenantId>({
      query: ({ id, tid }) => ({
        url: `/tid/${tid}/${RESOURCE}/${id}/delete/`,
        method: 'DELETE',
      }),
      invalidatesTags: [TagType.OrganizationPlotProduct],
    }),

    updateOrganizationPlotProduct: build.mutation<
      OrganizationPlotCultivationUpdateOutput,
      UpdateParamsWithTenantId<OrganizationPlotCultivationUpdateInput>
    >({
      query: ({ body, id, tid }) => ({
        body,
        url: `/tid/${tid}/${RESOURCE}/${id}/update/`,
        method: 'PATCH',
      }),
      invalidatesTags: [TagType.OrganizationPlotProduct],
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateOrganizationPlotProductMutation,
  useDeleteOrganizationPlotProductMutation,
  useGetOrganizationPlotProductListQuery,
  useGetOrganizationPlotProductQuery,
  useUpdateOrganizationPlotProductMutation,
} = organizationPlotProductEndpoints;
