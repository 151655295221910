import { type PayloadAction } from '@reduxjs/toolkit';
import { type ModuleListOutputItem } from 'src/__generated__/InternalApiTypes';
import { type TransformedOrganizationProductListOutputItem } from 'src/services/farmApi';

import { type AssessmentConfigState } from '../types';
import { buildRowsColumns } from '../utils/buildRowsColumns';

export interface SetLayoutPayload {
  productList: TransformedOrganizationProductListOutputItem[];
  moduleList: ModuleListOutputItem[] | undefined;
}

/**
 *
 * @param state -
 * @param action -
 * @returns
 */
export const setLayout = (
  state: AssessmentConfigState,
  action: PayloadAction<SetLayoutPayload>,
): AssessmentConfigState => ({
  ...state,
  ...buildRowsColumns(action.payload.productList, action.payload.moduleList),
});
