import { type TransformedOrganizationProductListOutputItem } from 'src/services/farmApi';

import { type MatrixRow } from '../types';

/**
 * Converts a product list item to a matrix row.
 * @param product - Product to convert
 * @returns
 */
export const productToMatrixRow = (
  product: TransformedOrganizationProductListOutputItem,
): MatrixRow => ({
  label: product.name,
  id: product.uuid,
  product: product.product,
  productionTechnique: product.production_technique,
});
