import { Navigate, Route, Routes } from 'react-router-dom';
import { useNavigateMainPage } from 'src/hooks';
import { ROUTES } from 'src/settings';

export const AppRoutes = () => {
  const mainPageRoute = useNavigateMainPage();
  return (
    <Routes>
      {ROUTES.map((route) => (
        <Route
          key={route.path}
          element={route.element}
          path={route.path}
        />
      ))}
      <Route
        element={
          <Navigate
            replace
            to={mainPageRoute}
          />
        }
        path="/"
      />
    </Routes>
  );
};
