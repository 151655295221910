import { Typography } from '@mui/material';
import {
  DataGridPro,
  type DataGridProProps,
  type GridApiPro,
  GridLoadingOverlay,
  GridOverlay,
  type GridRowModel,
  type GridSortModel,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';
import AgriplaceLoadingLogo from 'assets/agriplace-loading-logo.svg';
import { useRef } from 'react';
import { commonStrings, mobileCompatibleDataGridStrings } from 'src/languages/en-UK';
import { DEFAULT_DATA_GRID_PAGINATION, DEFAULT_DATA_GRID_STATE } from 'src/settings';

interface MobileCompatibleDataGridProps extends DataGridProProps {
  hasAgriplaceLoadingAnimation?: boolean;
  hideToolbar?: boolean;
  noRowsOverlay?: React.JSXElementConstructor<string>;
  customToolbarComponent?: () => React.JSX.Element;
  sortModel?: GridSortModel;
}

type NoDataOverlayProps = {
  message: string;
};

const NoDataOverlay = ({ message }: NoDataOverlayProps): React.JSX.Element => (
  <Typography
    sx={{
      alignItems: 'center',
      color: 'text.secondary',
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
      px: 1,
      py: 0.5,
      textAlign: 'center',
      whiteSpace: 'pre-line',
    }}
    variant="overline"
  >
    {message}
  </Typography>
);

export const CustomNoRowsOverlay = (msg: string) => <NoDataOverlay message={msg} />;

/**
 * Especially for mobile requirements and transifex text replacement, creating and managing a single component instead of using the same props over and over again facilitated code repetition and management.
 */
export const MobileCompatibleDataGrid = ({
  apiRef,
  customToolbarComponent,
  noRowsOverlay,
  sortModel,
  checkboxSelection = false,
  disableColumnSelector = true,
  disableDensitySelector = true,
  disableRowSelectionOnClick = true,
  hasAgriplaceLoadingAnimation = false,
  hideToolbar = false,
  pageSizeOptions = DEFAULT_DATA_GRID_PAGINATION,
  ...restProps
}: MobileCompatibleDataGridProps) => {
  const getRowId = (row: GridRowModel) => row.uuid;
  const tableRef = useRef<GridApiPro>();
  const handlePageChange = () => {
    tableRef?.current?.scroll?.({ left: 0, top: 0 });
  };

  const customLoadingOverlay = () => (
    <GridOverlay>
      <img
        alt="AgriPlace logo with loading animation"
        height="48px"
        src={AgriplaceLoadingLogo}
        width="48px"
      />
    </GridOverlay>
  );

  const customToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
    </GridToolbarContainer>
  );

  return (
    <DataGridPro
      // @ts-expect-error MUI ref type issue
      apiRef={apiRef || tableRef}
      checkboxSelection={checkboxSelection}
      disableColumnSelector={disableColumnSelector}
      disableDensitySelector={disableDensitySelector}
      disableRowSelectionOnClick={disableRowSelectionOnClick}
      getRowId={getRowId}
      initialState={{
        ...DEFAULT_DATA_GRID_STATE,
        ...(sortModel && { sorting: { sortModel } }),
      }}
      localeText={{
        MuiTablePagination: {
          labelRowsPerPage: mobileCompatibleDataGridStrings('rowsPerPage'),
        },
        checkboxSelectionHeaderName: mobileCompatibleDataGridStrings('checkboxSelection'),
        columnsManagementShowHideAllText: mobileCompatibleDataGridStrings('showHideAll'),
        columnsManagementSearchTitle: mobileCompatibleDataGridStrings('searchTitle'),
        filterOperatorContains: mobileCompatibleDataGridStrings('contains'),
        filterOperatorEquals: mobileCompatibleDataGridStrings('equals'),
        filterOperatorStartsWith: mobileCompatibleDataGridStrings('startsWith'),
        filterOperatorEndsWith: mobileCompatibleDataGridStrings('endsWith'),
        filterOperatorIsEmpty: mobileCompatibleDataGridStrings('isEmpty'),
        filterOperatorIsNotEmpty: mobileCompatibleDataGridStrings('isNotEmpty'),
        filterOperatorIsAnyOf: mobileCompatibleDataGridStrings('isAnyOf'),
        filterPanelColumns: mobileCompatibleDataGridStrings('columns'),
        filterPanelDeleteIconLabel: commonStrings('delete'),
        filterPanelInputLabel: mobileCompatibleDataGridStrings('value'),
        filterPanelInputPlaceholder: mobileCompatibleDataGridStrings('filterValue'),
        filterPanelOperator: mobileCompatibleDataGridStrings('operator'),
        footerRowSelected: (count) => `${count} ${mobileCompatibleDataGridStrings('rowSelected')}`,
        toolbarColumns: mobileCompatibleDataGridStrings('columns'),
        toolbarColumnsLabel: mobileCompatibleDataGridStrings('columns'),
        toolbarDensity: mobileCompatibleDataGridStrings('density'),
        toolbarDensityComfortable: mobileCompatibleDataGridStrings('comfortable'),
        toolbarDensityCompact: mobileCompatibleDataGridStrings('compact'),
        toolbarDensityLabel: mobileCompatibleDataGridStrings('density'),
        toolbarDensityStandard: commonStrings('standard'),
        toolbarExport: mobileCompatibleDataGridStrings('export'),
        toolbarExportCSV: mobileCompatibleDataGridStrings('downloadAsCSV'),
        toolbarExportLabel: mobileCompatibleDataGridStrings('export'),
        toolbarExportPrint: mobileCompatibleDataGridStrings('print'),
        toolbarFilters: commonStrings('filters'),
        toolbarFiltersLabel: commonStrings('filters'),
        toolbarFiltersTooltipHide: mobileCompatibleDataGridStrings('hideFilters'),
        toolbarFiltersTooltipShow: mobileCompatibleDataGridStrings('showFilters'),
      }}
      onPaginationModelChange={handlePageChange}
      pageSizeOptions={pageSizeOptions}
      pagination
      slots={{
        noRowsOverlay,
        loadingOverlay: hasAgriplaceLoadingAnimation ? customLoadingOverlay : GridLoadingOverlay,
        ...(!hideToolbar && { toolbar: customToolbarComponent || customToolbar }),
      }}
      {...restProps}
    />
  );
};
