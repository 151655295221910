import { CssBaseline, ThemeProvider } from '@mui/material';
import { PseudoTranslationPolicy, SourceStringPolicy } from '@transifex/native';
import { useTX } from '@transifex/react';
import { type ReactNode, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { AuthStatus } from 'src/enums/AuthStatus';
import { useGetCurrentUserProfile, useUserAuth } from 'src/hooks';
import { useGetOrCreateUserProfileMutation } from 'src/services/farmApi';
import { ROUTE_PATH_CREATE_ORGANIZATION, ROUTES } from 'src/settings';

import { AppAccountMenu, AppHeader, AppOrganizationSwitcher, LoadingArea } from '../common';
import { SnackbarDelegate } from '../common/SnackbarDelegate';
import { TenantProvider } from '../TenantProvider';
import { ThemeContext } from '../ThemeProvider';

export const App = ({ children }: { children?: ReactNode }): React.JSX.Element => {
  // Set-up
  const [getOrCreateUser] = useGetOrCreateUserProfileMutation();
  const { authStatus, userId } = useUserAuth();
  const location = useLocation();

  const {
    data: userProfile,
    isLoading: isUserProfileLoading,
    isFetching: isUserProfileFetching,
    isError: isUserProfileError,
  } = useGetCurrentUserProfile() ?? {};

  // If AWS account exists, but AgriPlace user profile is missing, create it
  useEffect(() => {
    if (
      userId &&
      authStatus === AuthStatus.LoggedIn &&
      (!isUserProfileLoading || !isUserProfileFetching) &&
      (!userProfile || isUserProfileError)
    ) {
      getOrCreateUser(userId);
    }
  }, [
    userId,
    userProfile,
    isUserProfileLoading,
    isUserProfileFetching,
    isUserProfileError,
    authStatus,
    getOrCreateUser,
  ]);

  /* User is "fully" logged in if the userId (from AWS) and the userProfile (from AgriPlace) are
   * both available.
   */
  const userIsFullyLoggedIn =
    authStatus === AuthStatus.LoggedIn &&
    userId &&
    userProfile &&
    !isUserProfileLoading &&
    !isUserProfileFetching;

  // Initiate Transifex and listen for change in user language
  const tx = useTX();
  tx.init({
    token: '1/4e04b6d5146651d10eecce7c5813938336534f3b',
    missingPolicy:
      window.appConfig.environmentName === 'prod' || process.env.NODE_ENV === 'dev'
        ? new SourceStringPolicy()
        : new PseudoTranslationPolicy(),
  });
  const language = userProfile?.language ?? navigator.language.split(/[-_]/)[0];
  if (language) {
    tx.setCurrentLocale(language);
    localStorage?.setItem('selectedLanguage', language);
  }

  useEffect(() => {
    if (userProfile?.language) {
      tx.setCurrentLocale(userProfile.language);
      localStorage.setItem('selectedLanguage', userProfile.language);
    }
  }, [userProfile?.language, tx]);

  // Divide routes into primary and secondary for header and account menu respectively
  const primaryRoutes = ROUTES.filter((route) => route.visibility.includes('header'));
  const accountRoutes = ROUTES.filter((route) => route.visibility.includes('userMenu'));

  const userHasOrganizations = userProfile?.memberships && userProfile.memberships.length > 0;

  const { theme } = useContext(ThemeContext);
  // If log-in check is in progress or we still don't have a user profile, show a loading spinner
  if (authStatus === AuthStatus.Unknown || (authStatus === AuthStatus.LoggedIn && !userProfile)) {
    return (
      <LoadingArea
        hasAgriplaceLoadingAnimation
        size={72}
      />
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <TenantProvider>
        {(!userHasOrganizations && userIsFullyLoggedIn) ||
          (userHasOrganizations && (
            <AppHeader
              routes={
                userHasOrganizations && location.pathname !== ROUTE_PATH_CREATE_ORGANIZATION
                  ? primaryRoutes
                  : []
              }
            >
              <AppOrganizationSwitcher />
              <AppAccountMenu
                routes={
                  userHasOrganizations && location.pathname !== ROUTE_PATH_CREATE_ORGANIZATION
                    ? accountRoutes
                    : []
                }
              />
            </AppHeader>
          ))}
        {children}
        <SnackbarDelegate />
      </TenantProvider>
    </ThemeProvider>
  );
};
