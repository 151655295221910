import { createSlice } from '@reduxjs/toolkit';

import { changeMalusPoint as changeMalusPointReducer } from './reducers/changeMalusPoint';
import { changeProductSelection as changeProductSelectionReducer } from './reducers/changeProductSelection';
import { changeSelection as changeSelectionReducer } from './reducers/changeSelection';
import { changeStandardSelection as changeStandardSelectionReducer } from './reducers/changeStandardSelection';
import { rebuildFromAssessment as rebuildFromAssessmentReducer } from './reducers/rebuildFromAssessment';
import { setLayout as setLayoutReducer } from './reducers/setLayout';
import { type AssessmentConfigState } from './types';

const initialState: AssessmentConfigState = {
  columns: [],
  rows: [],
  selections: [],
  initialized: false,
};

export const assessmentConfigSlice = createSlice({
  initialState,
  name: 'assessmentConfig',
  reducers: {
    changeSelection: changeSelectionReducer,
    changeStandardSelection: changeStandardSelectionReducer,
    changeProductSelection: changeProductSelectionReducer,
    changeMalusPoint: changeMalusPointReducer,
    rebuildFromAssessment: rebuildFromAssessmentReducer,
    setLayout: setLayoutReducer,
    reset: (): AssessmentConfigState => initialState,
  },
});

export const { actions: assessmentConfigActions, reducer: assessmentConfigReducer } =
  assessmentConfigSlice;
